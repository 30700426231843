import React from 'react';
import { Message } from '@twilio/conversations';
import MessageInfo from './MessageInfo/MessageInfo';
import MessageListScrollContainer from './MessageListScrollContainer/MessageListScrollContainer';
import TextMessage from './TextMessage/TextMessage';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import MediaMessage, { StreamingMedia } from './MediaMessage/MediaMessage';

interface MessageListProps {
  messages: Message[];
}

const getFormattedTime = (message?: Message) =>
  message?.dateCreated?.toLocaleTimeString('en-us', { hour: 'numeric', minute: 'numeric' }).toLowerCase();

export default function MessageList({ messages }: MessageListProps) {
  const { room } = useVideoContext();
  const localParticipant = room?.localParticipant;

  return (
    <MessageListScrollContainer messages={messages}>
      {messages.map((message, idx) => {
        const time = getFormattedTime(message) ?? '';
        const previousTime = getFormattedTime(messages[idx - 1]);

        // Display the MessageInfo component when the author or formatted timestamp differs from the previous message
        const shouldDisplayMessageInfo = time !== previousTime || message.author !== messages[idx - 1]?.author;

        const isLocalParticipant = localParticipant?.identity === message.author;

        let media: StreamingMedia | null = null;
        if (message?.body) {
          try {
            const sendMediaOptions = JSON.parse(message.body);
            if (
              sendMediaOptions.contentType &&
              sendMediaOptions.filename &&
              sendMediaOptions.media &&
              sendMediaOptions.size
            ) {
              media = {
                filename: sendMediaOptions.filename,
                size: sendMediaOptions.size,
                contentType: sendMediaOptions.contentType,
                base64url: sendMediaOptions.media,
              };
            }
          } catch (e) {
            //
          }
        }

        return (
          <React.Fragment key={message.sid}>
            {shouldDisplayMessageInfo && (
              <MessageInfo author={message.author ?? ''} isLocalParticipant={isLocalParticipant} dateCreated={time} />
            )}
            {message.type === 'text' && !media && (
              <TextMessage body={message.body ?? ''} isLocalParticipant={isLocalParticipant} />
            )}
            {media && <MediaMessage media={media} />}
          </React.Fragment>
        );
      })}
    </MessageListScrollContainer>
  );
}
