import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
// import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import EndCallButton from '../../customize/components/Buttons/EndCallButton/EndCallButton';
import { isMobile } from '../../utils';
import Menu from './Menu/Menu';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid, Hidden } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
//import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
//import ToggleFileButton from '../Buttons/ToggleFileButton/ToggleFileButton';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
import useCanvasDisabled from '../../hooks/useCanvasDisabled/useCanvasDisabled';
//import MemoWindow from '../MemoWindow/MemoWindow';

// 新規
//import ToggleCanvasDrawingToolsButton from '../Buttons/ToggleCanvasDrawingToolsButton/ToggleCanvasDrawingToolsButton';
import SimpleCanvasDrawingToolsContainer from '../SimpleCanvasDrawingToolsContainer/SimpleCanvasDrawingToolsContainer';
import { ZINDEX } from '../../constants';

// メモアイコン
//import memoIcon from '../../assets/MemoIcon.svg';
import useStrokeState from '../../hooks/useStrokeState/useStrokeState';

import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import ToggleStandByButton from '../Buttons/toggleStandByButton/toggleStandByButton';
import { useAppState } from '../../state';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      //backgroundColor: '#f5c4ef',
      marginRight: '6px',
    },
    container: {
      backgroundColor: theme.palette.background.default,
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: 'fixed',
      display: 'flex',
      padding: '0 1.43em',
      zIndex: ZINDEX.MENU_BAR_CONTAINER,
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
      },
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: ZINDEX.SCREEN_SHARE_BANNER,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const [isCanvasDisplay, toggleCanvasDisplay] = useState(false);
  const isDisabled = useCanvasDisabled();
  const screenShareParticipant = useScreenShareParticipant();
  const { setShowCanvas } = useStrokeState();
  // eslint-disable-next-line
  const { isMaster, setIsStandBy, setMasterIs, isPatient } = useAppState();
  //const { /*broadcastIsStandBy,*/ queryIsStandBy, broadcastAudioVideoInfo } = useRemoteControl();
  const [isInit, setisInit] = useState(true);

  useEffect(() => {
    // フラグが反転した意味で利用されていて時間を食った showされているときfalse
    const enabled = !isDisabled;
    setShowCanvas((isCanvasDisplay ? false : true) && enabled);
    if (isInit) {
      setisInit(false);
      // masterの場合は初期値としてstandBy現況を送信
      if (isMaster) {
        // 暫定でマスターは必ずstandBy状態で接続
        //setIsStandBy(true);
        //broadcastIsStandBy(true);
      } else {
        // マスターでない場合はリクエスト
        /*queryIsStandBy().then(result => {
          if (result) {
            // eslint-disable-next-line
            //setMasterIs((result as any).masterName);
            // eslint-disable-next-line
            //setIsStandBy((result as any).isStandBy);
          }
        });*/
        // 音声映像状態をマスターに送信
        //broadcastAudioVideoInfo();
      }
    }
    // eslint-disable-next-line
  }, [isCanvasDisplay, isDisabled, setShowCanvas]);

  const localToggleScreenShare = () => {
    toggleScreenShare();
  };

  return isPatient ? (
    <footer className={classes.container} style={{ overflow: 'hidden', height: 'auto' }}>
      <Grid container justifyContent="space-around" alignItems="center">
        <Hidden smDown>
          <Grid style={{ flex: 1 }}>
            {/* <Typography variant="body1">{room!.name}</Typography> */}
            <Typography variant="body1"></Typography>
          </Grid>
        </Hidden>
        <div /*style={{ maxWidth: 0, minWidth: 0, maxHeight: 0, minHeight: 0, overflow: 'hidden' }}*/>
          <ToggleAudioButton disabled={isReconnecting} id="toggle-audio-on-menu" />
        </div>
        <Grid item>
          <Grid container justifyContent="center" style={{ height: '53px' }}></Grid>
        </Grid>
        <Hidden smDown>
          <Grid style={{ flex: 1 }}>
            <Grid container justifyContent="flex-end">
              <EndCallButton hide={!isMaster} />
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </footer>
  ) : (
    <>
      {/* {isSharingScreen && (
        <Grid container justifyContent="center" alignItems="center" className={classes.screenShareBanner}>
          <Typography variant="h6">あなたの画面を共有しています</Typography>
          <Button onClick={() => toggleScreenShare()}>停止</Button>
        </Grid>
      )} */}
      <footer className={classes.container} style={{ overflow: 'auto', height: 'auto' }}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              {/* <Typography variant="body1">{room!.name}</Typography> */}
              <Typography variant="body1"></Typography>
            </Grid>
          </Hidden>
          <Grid item>
            <Grid container justifyContent="center" style={{ padding: '8px', rowGap: '8px' }}>
              <ToggleAudioButton disabled={isReconnecting} id="toggle-audio-on-menu" />
              <ToggleVideoButton disabled={isReconnecting} />
              {!(isSharingScreen || screenShareParticipant) && !isMobile && (
                <ToggleScreenShareButton disabled={isReconnecting} />
              )}
              {/* <ToggleCanvasDrawingToolsButton onClick={() => {console.log('AVX')}}/> */}
              {!isCanvasDisplay && (
                <div style={{ position: 'absolute', pointerEvents: 'none' }}>
                  <SimpleCanvasDrawingToolsContainer />
                </div>
              )}
              {/*process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && <ToggleChatButton />*/}
              <ToggleStandByButton disabled={isReconnecting} id="stand-by-button" />
              {/* <Button
                onClick={() => toggleMemoDisplay(!isMemoDisplay)}
                style={{ marginRight: '6px' }}
                className={classes.button}
              >
                <img className="card_label_img" src={memoIcon} style={{ display: 'none' }} />
                {isMemoDisplay ? 'メモ非表示' : 'メモ表示'}
              </Button> */}
              {/*<MemoWindow />*/}
              {/*<ToggleFileButton />*/}
              <Menu />
              {isSharingScreen && !isDisabled && (
                <Button onClick={() => localToggleScreenShare()} className={classes.button} id="stop-sharing-screen">
                  ■ 画面共有停止
                </Button>
              )}
              {!isDisabled && (
                <Button
                  onClick={() => toggleCanvasDisplay(!isCanvasDisplay)}
                  style={{ marginRight: '6px' }}
                  className={classes.button}
                >
                  {isCanvasDisplay && 'キャンバス表示'}
                  {!isCanvasDisplay && 'キャンバス非表示'}
                </Button>
              )}
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Grid container justifyContent="flex-end">
                <EndCallButton hide={!isMaster} />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  );
}
