import React, { useEffect, useState } from 'react';
import { FormControl, MenuItem, Typography, Select, Grid } from '@material-ui/core';
import useRemoteControl from '../../../hooks/useRemoteControl/useRemoteControl';
//import MicOffIcon from '../../../icons/MicOffIcon';
//import MicIcon from '../../../icons/MicIcon';

export interface OppositeAudioInputListPropType {
  id?: string;
  disabled?: boolean;
  className?: string;
  oppositeName: string;
  isAudioEnabled: boolean;
}

const OppositeAudioInputList = (props: OppositeAudioInputListPropType) => {
  const [neededWaiting, setNeededWaiting] = React.useState(false);
  const { replaceOppositeAudioTrack, getOppositeAudioInputDevices } = useRemoteControl();

  const [audioInputDevices, setAudioInputDevices] = useState<object[] | null>(null);
  const [oppositeAudioInputDeviceId, setOppositeAudioInputDeviceId] = useState('');

  const _replaceTrack = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    if (e) {
      replaceOppositeAudioTrack(props.oppositeName, e.target.value as string);
      setOppositeAudioInputDeviceId(e.target.value as string);
    }
  };

  useEffect(() => {
    window.addEventListener('changeOppositeAudioInputEnable', _changeOppositeAudioInputEnable);
    return () => {
      window.removeEventListener('changeOppositeAudioInputEnable', _changeOppositeAudioInputEnable);
    };
    // eslint-disable-next-line
  }, []);

  const _changeOppositeAudioInputEnable =
    // eslint-disable-next-line
    (e: any) => {
      if (e && e.detail !== undefined) {
        if (e.detail !== props.isAudioEnabled) {
          const muteController = document.getElementById('mute_controller');
          muteController?.click();
        }
      }
    };

  useEffect(() => {
    getOppositeAudioInputDevices(props.oppositeName).then((values: object | null) => {
      if (values) {
        // eslint-disable-next-line
        setAudioInputDevices((values as any).devices);
        // eslint-disable-next-line
        const selected_device = (values as any).idSelected || (values as any).devices[0].deviceId;
        setOppositeAudioInputDeviceId(selected_device);
        setNeededWaiting(false);
      }
    });
    // eslint-disable-next-line
  }, []);

  /*const _toggleAudioEnabled = () => {
    toggleMute(props.oppositeName, !props.isAudioEnabled);
  };*/

  return (
    <div>
      {audioInputDevices ? (
        <>
          <Typography variant="subtitle2" gutterBottom>
            音声入力（マイク等）
          </Typography>
          <Grid container alignItems="center" justifyContent="space-between">
            <div className="inputSelect">
              <FormControl fullWidth>
                <Select
                  onChange={_replaceTrack}
                  value={oppositeAudioInputDeviceId ?? ''}
                  variant="outlined"
                  disabled={neededWaiting}
                >
                  {audioInputDevices.length === 0 ? (
                    <>マイクがありません</>
                  ) : (
                    audioInputDevices.map(device => (
                      // eslint-disable-next-line
                      <MenuItem value={(device as any).deviceId} key={(device as any).deviceId}>
                        {/*eslint-disable-next-line*/}
                        {(device as any).label}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
              {/*<Button
                id={props.id || ''}
                style={{ marginTop: '4px', marginLeft: '398px' }}
                className={props.className}
                onClick={_toggleAudioEnabled}
                disabled={(audioInputDevices?.length ?? 0) === 0 || props.disabled}
                startIcon={props.isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
              >
                {(audioInputDevices?.length ?? 0) === 0 ? 'No Audio' : props.isAudioEnabled ? '音声ON' : '音声OFF'}
              </Button>*/}
            </div>
            {/*<AudioLevelIndicator audioTrack={localAudioTrack} color="black" />// リモートから取れたら取る*/}
          </Grid>
        </>
      ) : (
        <>
          <Typography variant="subtitle2" gutterBottom>
            音声入力（マイク等）
          </Typography>
          <Typography>取得中...</Typography>
        </>
      )}
    </div>
  );
};

export default React.memo(OppositeAudioInputList);
