import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY } from '../../../constants';
import { FormControl, MenuItem, Typography, Select, Button } from '@material-ui/core';
import { LocalVideoTrack } from 'twilio-video';
import { makeStyles } from '@material-ui/core/styles';
import VideoTrack from '../../VideoTrack/VideoTrack';
import useDevices from '../../../hooks/useDevices/useDevices';
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import VideoOffIcon from '../../../icons/VideoOffIcon';
import VideoOnIcon from '../../../icons/VideoOnIcon';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import useStateEx from '../../../hooks/useStateEx/useStateEx';

const useStyles = makeStyles({
  preview: {
    width: '300px',
    maxHeight: '200px',
    margin: '0.5em auto',
    '& video': {
      maxHeight: '200px',
    },
  },
});

export interface VideoInputListPropType {
  id?: string;
  disabled?: boolean;
  className?: string;
}

export default function VideoInputList(props: VideoInputListPropType) {
  const lastClickTimeRef = useRef(0);

  const classes = useStyles();
  const { videoInputDevices, hasVideoInputDevices } = useDevices();
  const { localTracks } = useVideoContext();
  const [neededWaiting, setNeededWaiting] = React.useState(false);
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();

  const localVideoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack | undefined;
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
  );
  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;
  // eslint-disable-next-line
  const [enabledVideo, setEnabledVideo, getEnabledVideo] = useStateEx(true);

  useEffect(() => {
    window.addEventListener('changeOppositeVideoInputEnable', _changeOppositeVideoInputEnable);
    return () => {
      window.removeEventListener('changeOppositeVideoInputEnable', _changeOppositeVideoInputEnable);
    };
    // eslint-disable-next-line
  }, []);

  function replaceTrack(value: string) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    //e.preventDefault();
    const newDeviceId = value;
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    setNeededWaiting(true);
    setTimeout(() => {
      localVideoTrack
        ?.restart({
          ...(DEFAULT_VIDEO_CONSTRAINTS as object),
          deviceId: { exact: newDeviceId },
        })
        .then(() => {
          setNeededWaiting(false);
        })
        // eslint-disable-next-line
        .catch((e: any) => {
          window.alert(e.toString());
        });
    }, 0);
  }

  useEffect(() => {
    setEnabledVideo(isVideoEnabled);
    // eslint-disable-next-line
  }, [isVideoEnabled]);

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  // eslint-disable-next-line
  const _changeOppositeVideoInputEnable = async (e: any) => {
    if (e && e.detail !== undefined) {
      const isVideoEnabled_ = await getEnabledVideo();
      if (e.detail !== isVideoEnabled_) {
        const muteController = document.getElementById('camera_block_controller');
        muteController?.click();
      }
    }
  };

  return (
    <div style={{ display: 'block' }}>
      {localVideoTrack && (
        <div className={classes.preview}>
          <VideoTrack isLocal track={localVideoTrack} key={localVideoTrack.name} />
        </div>
      )}
      {videoInputDevices.length > 1 ? (
        <FormControl fullWidth>
          <Typography variant="subtitle2" gutterBottom>
            ビデオ設定
          </Typography>
          <Select
            onChange={e => replaceTrack(e.target.value as string)}
            value={localVideoInputDeviceId || ''}
            variant="outlined"
            disabled={neededWaiting}
          >
            {videoInputDevices.map(device => (
              <MenuItem value={device.deviceId} key={device.deviceId}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <>
          <Typography variant="subtitle2" gutterBottom>
            ビデオ設定
          </Typography>
          <Typography>{localVideoTrack?.mediaStreamTrack.label || 'No Local Video'}</Typography>
        </>
      )}
      <Button
        id={props.id || 'camera_block_controller'}
        style={{ marginTop: '4px', marginLeft: '398px' }}
        className={props.className}
        onClick={toggleVideo}
        disabled={!hasVideoInputDevices || props.disabled}
        startIcon={isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
      >
        {!hasVideoInputDevices ? 'No Video' : isVideoEnabled ? 'カメラON' : 'カメラOFF'}
      </Button>
    </div>
  );
}
