import React, { useEffect, useState } from 'react';
import useDevices from '../../../hooks/useDevices/useDevices';
import { Box, Button } from '@material-ui/core';
import { useAppState } from '../../../state';
import Sitting from '../../../icons/sitting';
import { Participant } from 'twilio-video';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useRemoteControl from '../../../hooks/useRemoteControl/useRemoteControl';
import useParticipants from '../../../hooks/useParticipants/useParticipants';
import { Close } from '@material-ui/icons';
import useStateEx from '../../../hooks/useStateEx/useStateEx';

export interface ToggleStandByButtonProps {
  id?: string;
  disabled?: boolean;
  className?: string;
}

export interface Status {
  [index: string]: boolean;
}

interface Window {
  statuses: Status;
  addEventListener: (
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions | undefined
  ) => void;
  removeEventListener: (
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions | undefined
  ) => void;
  dispatchEvent: (event: Event) => boolean;
  //location: Location;
  //history: History;
  //encodeURI: (value: string) => string;
}
declare let window: Window;

const ToggleStandByButton = (props: ToggleStandByButtonProps) => {
  //const { broadcastIsStandBy } = useRemoteControl();
  const { whoIs, isMaster, masterIs } = useAppState();
  const { hasAudioInputDevices, hasVideoInputDevices } = useDevices();
  const { room } = useVideoContext();
  const { broadCastRequestStatus, clearPermission } = useRemoteControl();
  const participants = useParticipants();

  const [isOpenWaitingRoom, setIsOpenWaitingRoom, getIsOpenWaitingRoom] = useStateEx(false);
  const [names, setNames] = useState<string[] | null>(null);
  //const [statuses, setStatuses, getStatuses] = useStateEx<Status>({});
  const [changeStateCount, setChangeStateCount] = useState(0);
  const [updateView, setUpdateView, getUpdateView] = useStateEx(0);
  //const [isStandByMaster, setIsStandByMaster] = useState(true);

  useEffect(() => {
    const statuses_ = {};
    window.statuses = statuses_;
    setUpdateView(updateView + 1);
    //window.addEventListener('participantStandBy', _participantStandBy);
    window.addEventListener('responseStatus', _responseStatus);
    const handle = setInterval(_updateView, 1000);
    return () => {
      //window.removeEventListener('participantStandBy', _participantStandBy);
      window.removeEventListener('responseStatus', _responseStatus);
      clearInterval(handle);
    };
    // eslint-disable-next-line
  }, []);

  const _updateView = () => {
    getIsOpenWaitingRoom().then(isOpenWaitingRoom_ => {
      if (room && isOpenWaitingRoom_) {
        const temps = Array.from<Participant>(room.participants.values());
        let hasUndefined = false;
        for (const temp_ of temps) {
          if (window.statuses[temp_.identity] === undefined) {
            hasUndefined = true;
            break;
          }
        }
        if (whoIs && hasUndefined) {
          broadCastRequestStatus(whoIs, isMaster);
        }
      }
    });
    getUpdateView().then(value => {
      setUpdateView(value + 1);
    });
  };

  // eslint-disable-next-line
  const _responseStatus = (e: any) => {
    if (e && e.detail) {
      const statuses_ = { ...window.statuses };
      statuses_[e.detail.name] = e.detail.isStandBy;
      window.statuses = statuses_;
      //setUpdateView(updateView + 1);
    }
  };

  useEffect(() => {
    const queryParticipantStandByEvent = new CustomEvent('queryParticipantStandBy', {
      detail: null,
    });
    window.dispatchEvent(queryParticipantStandByEvent);
  }, [isOpenWaitingRoom]);

  // eslint-disable-next-line
  /*const _participantStandBy = async (e: any) => {
    if (e && e.detail && e.detail.name) {
      const statuses_ = { ...window.statuses };
      statuses_[e.detail.name] = e.detail.isStandBy;
      window.statuses = statuses_;
      setUpdateView(updateView + 1);
    } else {
      console.log('おかしいの出た');
    }
  };*/

  useEffect(() => {
    if (room && isOpenWaitingRoom) {
      const temps = Array.from<Participant>(room.participants.values());
      // roomの状態が変わった時にroomにいてnamesにいない奴は状態を取得する
      /*let hereItIs = false;
      for (const temp_ of temps) {
        for (const name_ of Object.keys(window.statuses)) {
          if (temp_.identity === name_) {
            hereItIs = true;
            break;
          }
        }
        if (!hereItIs) {
          setTimeout(() => broadCastRequestStatus(temp_.identity, isMaster), 2000);
        }
      }
      if (whoIs) {
          setTimeout(() => broadCastRequestStatus(whoIs, isMaster), 2000);
      }*/
      const names_: string[] = [];
      for (const temp of temps) {
        names_.push(temp.identity);
        //const statuses_ = { ...statuses };
        //statuses_[temp.identity] = true;
        //setStatuses(statuses_);
      }
      // 名前がなくなったら待合室ダイアログは閉じる
      /*if (names_.length === 0) {
        const standByButton = document.getElementById('stand-by-button');
        if (standByButton && isOpenWaitingRoom) {
          standByButton.click();
        }
        return;
      }*/
      // names_の中身でwindow.statusesの値を取ってきて、window.statusesをクリアしたあとそれを上書き
      const statuses_: Status = {};
      for (const name_ of names_) {
        if (window.statuses[name_] === undefined) {
          //setTimeout(() => broadCastRequestStatus(name_, isMaster), 3000);
        } else {
          const status_ = window.statuses[name_];
          statuses_[name_] = status_;
        }
      }
      window.statuses = statuses_;
      //setUpdateView(updateView + 1);
      setNames(names_);
    }

    // eslint-disable-next-line
  }, [room, isOpenWaitingRoom, participants]);

  // 操作画面を開くためのトリガーになった
  const _openPermission = () => {
    const temp = !isOpenWaitingRoom;
    setIsOpenWaitingRoom(temp);
    if (temp && whoIs) {
      // 名前収集済み
      // 状態収集
      broadCastRequestStatus(whoIs, isMaster);
    }
  };

  const _togglePermission = async (name: string) => {
    clearPermission(name);
    //const statuses_ = { ...(await getStatuses()) };
    window.statuses[name] = false;
    setChangeStateCount(changeStateCount + 1);
    //setStatuses(statuses_);
  };

  const _onClose = () => {
    const standByButton = document.getElementById('stand-by-button');
    if (standByButton) {
      standByButton.click();
    }
  };

  const _getIsStandBy = (name: string) => {
    for (const participant_ of participants) {
      if (participant_.identity === name) {
        return masterIs !== participant_.identity && window.statuses[name];
      }
    }
    return undefined;
  };

  return isMaster ? (
    <>
      <Button
        id={props.id || ''}
        className={props.className}
        onClick={_openPermission}
        disabled={props.disabled}
        startIcon={<Sitting />}
        /*style={{ backgroundColor: isStandBy ? '#0f4' : 'rgba(0,0,0,0)' }}*/
      >
        {!hasVideoInputDevices || !hasAudioInputDevices ? 'Condition matters' : '待合室'}
      </Button>
      {isOpenWaitingRoom && (
        <Box
          style={{
            position: 'fixed',
            left: '0px',
            top: '0px',
            right: '0px',
            bottom: '0px',
            backgroundColor: 'rgba(0,0,0,0)',
            padding: 'auto',
            pointerEvents: 'none',
          }}
          id="background-pane"
        >
          <div
            style={{
              display: 'table',
              width: '500px',
              backgroundColor: '#fff',
              color: '#000',
              textAlign: 'center',
              fontSize: '16pt',
              alignItems: 'center',
              marginLeft: 'auto',
              marginRight: '300px',
              marginTop: '165px',
              borderRadius: '8px',
              pointerEvents: 'visible',
            }}
            id="waiting-room-dialog"
          >
            <Box
              style={{
                padding: '8px',
                borderBottom: '1px solid #aaa',
                backgroundColor: '#f8f8f8',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                fontWeight: '600',
              }}
            >
              待合室
            </Box>
            <Box style={{ marginTop: '-41px', marginRight: '-452px' }}>
              <Button onClick={_onClose}>
                <Close />
              </Button>
            </Box>
            <Box style={{ padding: '12px' }}>
              {names && names.length === 0 && <>お待ち下さい...</>}
              {names &&
                names.map((name, index) => {
                  return (
                    <Box
                      key={index}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '12px 24px',
                        fontSize: '20pt',
                      }}
                    >
                      <Box>{name} 様</Box>
                      {/*<Box>{statuses[name] ? '不許可' : '許可中'}</Box>*/}
                      <Button
                        style={{ fontSize: '14pt', border: 'solid 1px #aaa' }}
                        onClick={() => _togglePermission(name)}
                        disabled={!window.statuses ? true : !window.statuses[name] ?? false}
                      >
                        {!window.statuses || _getIsStandBy(name) === undefined
                          ? '検出中'
                          : _getIsStandBy(name)
                          ? '許可する'
                          : '許可済み'}
                      </Button>
                    </Box>
                  );
                })}
            </Box>
          </div>
          <Box sx={{ width: 0, height: 0, color: '#f00' }}>{updateView}</Box>
        </Box>
      )}
    </>
  ) : (
    <></>
  );
};

export default ToggleStandByButton;
