import React, { useEffect, useState } from 'react';
import { Box, Button, Slider } from '@material-ui/core';
import { VolumeDown, VolumeUp } from '@material-ui/icons';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import MicIcon from '../../../icons/MicIcon';
import MicOffIcon from '../../../icons/MicOffIcon';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export interface AudioGainPropType {
  id?: string;
  disabled?: boolean;
  className?: string;
  isAudioEnabled: boolean;
}

const AudioGain = (props: AudioGainPropType) => {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

  const [gainValue, setGainValue] = useState(0);

  useEffect(() => {
    window.addEventListener('changeOppositeAudioInputGainValue', _changeOppositeAudioInputGainValue);
    //window.addEventListener('changeOppositeAudioInputEnable', _changeOppositeAudioInputEnable);
    return () => {
      window.removeEventListener('changeOppositeAudioInputGainValue', _changeOppositeAudioInputGainValue);
      //window.removeEventListener('changeOppositeAudioInputEnable', _changeOppositeAudioInputEnable);
    };
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const _changeOppositeAudioInputGainValue = (e: any) => {
    if (e && e.detail) {
      const value = e.detail as number | number[];
      const replaceOppositeActiveSinkEvent = new CustomEvent('changeAudioInputGainValue', {
        detail: value,
      });
      window.dispatchEvent(replaceOppositeActiveSinkEvent);
      setGainValue(value as number);
    }
  };

  const _changeGainValue = (event: React.ChangeEvent<object>, value: number | number[]) => {
    const replaceOppositeActiveSinkEvent = new CustomEvent('changeAudioInputGainValue', {
      detail: value,
    });
    window.dispatchEvent(replaceOppositeActiveSinkEvent);
    setGainValue(value as number);
  };

  /*const _changeOppositeAudioInputEnable =
    // eslint-disable-next-line
    (e: any) => {
      if (e && e.detail !== undefined) {
        if (e.detail !== isAudioEnabled) {
          const muteController = document.getElementById('mute_controller');
          muteController?.click();
        }
      }
    };*/

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ mb: 1, display: 'flex' }} alignItems="center">
        <Box style={{ marginRight: '4px' }}>音量</Box>
        <VolumeDown style={{ marginRight: '8px' }} />
        <Slider aria-label="Volume" value={gainValue} onChange={_changeGainValue} style={{ width: '246px' }} />
        <VolumeUp style={{ marginLeft: '8px' }} />
        <Box sx={{ marginLeft: '8px' }}>＋{gainValue}</Box>
      </Box>
      <Button
        id={props.id || 'mute_controller'}
        style={{ marginLeft: '24px', marginTop: '-5px' }}
        className={props.className}
        onClick={toggleAudioEnabled}
        disabled={!hasAudioTrack || props.disabled}
        startIcon={isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
        data-cy-audio-toggle
      >
        {!hasAudioTrack ? 'No Audio' : props.isAudioEnabled ? '音声ON' : '音声OFF'}
      </Button>
    </Box>
  );
};

export default React.memo(AudioGain);
