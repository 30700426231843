import React from 'react';

export default function SpeakerOffIcon() {
  return (
    <svg
      id="exportSVG"
      visibility="visible"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      data-info="draw.ninja"
      data-name="draw"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <g id="objectsExport" visibility="visible">
        <path
          id="path0"
          d="M0 5.77 L0 11.77 L4 11.77 L9 16.77 L9 0.77 L4 5.77 L0 5.77 Z M13.5 8.77 C13.5 7 12.48 5.48 11 4.74 C11 4.74 11 12.79 11 12.79 C12.48 12.06 13.5 10.54 13.5 8.77 Z M11 0 L11 2.06 C13.89 2.92 16 5.6 16 8.77 C16 11.94 13.89 14.62 11 15.48 C11 15.48 11 17.54 11 17.54 C15.01 16.63 18 13.05 18 8.77 C18 4.49 15.01 0.91 11 0 Z "
          data-centerx="9"
          data-centery="8.77"
          stroke="#282A2B"
          strokeWidth="0.5"
          data-sw="0.5"
          strokeOpacity="1"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
          fillOpacity="1"
          fillRule="evenodd"
          filter="none"
          visibility="visible"
          strokeDasharray="none"
          markerStart="none"
          markerEnd="none"
          data-name="curve"
          data-title="curve"
          data-grad="0"
          data-group="0"
          data-edit="1"
        ></path>
        <path
          fill="red"
          d="M12.146.646c.196-.195.512-.195.708 0 .173.174.192.443.057.638l-.057.07-12 12c-.196.195-.512.195-.708 0-.173-.174-.192-.443-.057-.638l.057-.07 12-12z"
          transform="translate(3.5 2)"
        ></path>
      </g>
    </svg>
  );
}
