import React, { useCallback, useEffect, useRef } from 'react';
import AudioLevelIndicator from '../../AudioLevelIndicator/AudioLevelIndicator';
import { LocalAudioTrack } from 'twilio-video';
import { FormControl, MenuItem, Typography, Select, Grid, Button } from '@material-ui/core';
import { SELECTED_AUDIO_INPUT_KEY } from '../../../constants';
import useDevices from '../../../hooks/useDevices/useDevices';
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import MicIcon from '../../../icons/MicIcon';
import MicOffIcon from '../../../icons/MicOffIcon';
import useStateEx from '../../../hooks/useStateEx/useStateEx';

export interface AudioInputListPropType {
  id?: string;
  disabled?: boolean;
  className?: string;
}

const AudioInputList = (props: AudioInputListPropType) => {
  const lastClickTimeRef = useRef(0);

  const { audioInputDevices } = useDevices();
  const { localTracks } = useVideoContext();
  const [neededWaiting, setNeededWaiting] = React.useState(false);

  const localAudioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const srcMediaStreamTrack = localAudioTrack?.noiseCancellation?.sourceTrack;
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

  const [enabledAudio, setEnabledAudio, getEnabledAudio] = useStateEx(true);

  const localAudioInputDeviceId =
    srcMediaStreamTrack?.getSettings().deviceId || mediaStreamTrack?.getSettings().deviceId;
  // eslint-disable-next-line
  function replaceTrack(e: any) {
    const newDeviceId = e.target.value;
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    setNeededWaiting(true);
    setTimeout(() => {
      localAudioTrack
        ?.restart({ deviceId: { exact: newDeviceId } })
        .then(() => {
          //localAudioInputDeviceId =
          //  srcMediaStreamTrack?.getSettings().deviceId || mediaStreamTrack?.getSettings().deviceId;
          setNeededWaiting(false);
        })
        .catch(e => {
          window.alert(e.toString());
        });
    }, 0);
  }

  useEffect(() => {
    window.addEventListener('changeOppositeAudioInputEnable', _changeOppositeAudioInputEnable);
    return () => {
      window.removeEventListener('changeOppositeAudioInputEnable', _changeOppositeAudioInputEnable);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setEnabledAudio(isAudioEnabled);
    // eslint-disable-next-line
  }, [isAudioEnabled]);

  // eslint-disable-next-line
  const _changeOppositeAudioInputEnable = async (e: any) => {
    if (e && e.detail !== undefined) {
      const isAudioEnabled_ = await getEnabledAudio();
      if (e.detail !== isAudioEnabled_) {
        const muteController = document.getElementById('audio_mute_controller');
        muteController?.click();
      }
    }
  };

  const toggleAudio = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleAudioEnabled();
    }
  }, [toggleAudioEnabled]);

  return (
    <div>
      <Typography variant="subtitle2" gutterBottom>
        音声入力（マイク等）
      </Typography>
      <Grid container alignItems="center" justifyContent="space-between">
        <div className="inputSelect">
          {audioInputDevices.length > 1 ? (
            <FormControl fullWidth>
              <Select
                onChange={e => replaceTrack(e)}
                value={localAudioInputDeviceId || ''}
                variant="outlined"
                disabled={neededWaiting}
              >
                {audioInputDevices.map(device => (
                  <MenuItem value={device.deviceId} key={device.deviceId}>
                    {device.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Typography>{localAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}</Typography>
          )}
        </div>
        <AudioLevelIndicator audioTrack={localAudioTrack} color="black" />
      </Grid>
      <Button
        id={props.id || 'audio_mute_controller'}
        style={{ marginTop: '4px', marginLeft: '398px' }}
        className={props.className}
        onClick={toggleAudio}
        disabled={!hasAudioTrack || props.disabled}
        startIcon={isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
      >
        {!hasAudioTrack ? 'No Audio' : isAudioEnabled ? '音声ON' : '音声OFF'}
      </Button>
    </div>
  );
};

export default React.memo(AudioInputList);
