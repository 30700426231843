import { useState } from 'react';

function useStateEx<T>(initialState: T) {
  const [state, setState] = useState<T>(initialState);
  const getLatestState = () => {
    return new Promise<T>(resolve => {
      setState(s => {
        resolve(s);
        return s;
      });
    });
  };

  return [state, setState, getLatestState] as const;
}

export default useStateEx;
