import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { Client } from '../../modules/conversations';
import { Conversation } from '@twilio/conversations';
import { Message } from '@twilio/conversations';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import WebSocketClient from '../../modules/webSocketClient';

type ChatContextType = {
  isChatWindowOpen: boolean;
  setIsChatWindowOpen: (isChatWindowOpen: boolean) => void;
  connect: (token: string) => void;
  disconnect: () => void;
  hasUnreadMessages: boolean;
  messages: Message[];
  conversation: Conversation | null;
};

export const ChatContext = createContext<ChatContextType>({} as ChatContextType);

declare global {
  interface Window {
    chatClient: WebSocketClient;
    chatConversation: Conversation;
  }
}

export const ChatProvider: React.FC = ({ children }) => {
  const { room, onError } = useVideoContext();
  const isChatWindowOpenRef = useRef(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [chatClient, setChatClient] = useState<WebSocketClient>();
  const [sessionId, setSessionId] = useState<string | undefined>();

  const connect = useCallback(
    (token: string) => {
      setSessionId(token);
      Client.create(token)
        .then(client => {
          window.chatClient = client;
          setChatClient(client);
        })
        .catch(() => {
          onError(new Error('There was a problem connecting to My conversation service.'));
        });
    },
    [onError]
  );

  const disconnect = async () => {
    await Client.disconnect();
  };

  useEffect(() => {
    if (conversation) {
      // メッセージ受領イベントハンドリング
      const handleMessageAdded = (message: Message) => {
        setMessages(oldMessages => [...oldMessages, message]);
      };
      // ソケット切断イベントハンドリング
      /*const handleSocketClosed = () => {
        return sessionId && connect(sessionId);
      };*/
      // ソケットエラーイベントハンドリング
      /*const handleSocketError = () => {
        onError(new Error('There was a problem connecting to My conversation service.'));
      };*/
      conversation.getMessages().then(newMessages => setMessages(newMessages.items));
      conversation.on('messageAdded', handleMessageAdded);
      //conversation.on('socketClosed', handleSocketClosed);
      //conversation.on('socketError', handleSocketError);
      return () => {
        conversation.off('messageAdded', handleMessageAdded);
        //conversation.off('socketClosed', handleSocketClosed);
        //conversation.off('socketError', handleSocketError);
      };
    }
  }, [conversation, connect, sessionId, onError]);

  useEffect(() => {
    // If the chat window is closed and there are new messages, set hasUnreadMessages to true
    if (!isChatWindowOpenRef.current && messages.length) {
      setHasUnreadMessages(true);
    }
  }, [messages]);

  useEffect(() => {
    isChatWindowOpenRef.current = isChatWindowOpen;
    if (isChatWindowOpen) setHasUnreadMessages(false);
  }, [isChatWindowOpen]);

  useEffect(() => {
    if (room && chatClient) {
      chatClient
        .getConversationByUniqueName(room)
        .then(newConversation => {
          window.chatConversation = newConversation;
          setConversation(newConversation);
        })
        .catch(() => {
          //onError(new Error('この部屋に関連する未使用機能（チャット）の取得に問題がありました。'));
        });
    }
  }, [room, chatClient, onError]);

  return (
    <ChatContext.Provider
      value={{ isChatWindowOpen, setIsChatWindowOpen, connect, disconnect, hasUnreadMessages, messages, conversation }}
    >
      {children}
    </ChatContext.Provider>
  );
};
