//import { useEffect } from 'react';
import { NativeApi } from './nativeApi';
import NativeApiManager from './nativeApiManager';

export function useNativeApi(): [NativeApi, NativeApiManager] {
  const nativeApiManager = new NativeApiManager();
  const nativeApi = new NativeApi(nativeApiManager);
  // useEffect(() => {
  //     nativeApi.init()
  // }, [])
  return [nativeApi, nativeApiManager];
}
