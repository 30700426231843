import React, { useEffect, useState } from 'react';
import { Box, Button, Slider } from '@material-ui/core';
import { VolumeDown, VolumeUp } from '@material-ui/icons';
import useRemoteControl from '../../../hooks/useRemoteControl/useRemoteControl';
import { AudioTrack, LocalAudioTrack, RemoteAudioTrack } from 'twilio-video';
import MicIcon from '../../../icons/MicIcon';
import MicOffIcon from '../../../icons/MicOffIcon';
import useIsTrackEnabled from '../../../hooks/useIsTrackEnabled/useIsTrackEnabled';

export interface OppositeAudioGainPropType {
  name: string;
  audioTrack: AudioTrack;
  id?: string;
  disabled?: boolean;
  className?: string;
}

const OppositeAudioGain = (props: OppositeAudioGainPropType) => {
  const { requestChangeOppositeAudioInputGainValue } = useRemoteControl();
  const { toggleMute } = useRemoteControl();

  const [gainValue, setGainValue] = useState(33);

  useEffect(() => {
    /*navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        const audioContext = new AudioContext(); // Create a new audioContext for each audio indicator
        const source = audioContext.createMediaStreamSource(stream);
        const destin = audioContext.createMediaStreamDestination();
        const gainNode = audioContext.createGain();
        source.connect(gainNode);
        gainNode.connect(destin);

        gainNode.gain.setValueAtTime(gainValue / 100, audioContext.currentTime);
      })
      .catch(e => alert('error' + e.message));*/
  }, []);

  /*const _initialize = (stream: MediaStream) => {
    const audioContext = new AudioContext(); // Create a new audioContext for each audio indicator
    const source = audioContext.createMediaStreamSource(stream);
    const destin = audioContext.createMediaStreamDestination();
    const gainNode = audioContext.createGain();
    source.connect(gainNode);
    gainNode.connect(destin);
  };*/

  const _changeGainValue = (event: React.ChangeEvent<object>, value: number | number[]) => {
    requestChangeOppositeAudioInputGainValue(props.name, value as number);
    setGainValue(value as number);
  };

  const isTrackEnabled = useIsTrackEnabled(props.audioTrack as LocalAudioTrack | RemoteAudioTrack);
  const _toggleAudioEnabled = () => {
    toggleMute(props.name, !isTrackEnabled);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ mb: 1, display: 'flex' }} alignItems="center">
        <Box style={{ marginRight: '4px' }}>音量</Box>
        <VolumeDown style={{ marginRight: '8px' }} />
        <Slider aria-label="Volume" value={gainValue} onChange={_changeGainValue} style={{ width: '246px' }} />
        <VolumeUp style={{ marginLeft: '8px' }} />
        <Box sx={{ marginLeft: '8px' }}>＋{gainValue}</Box>
      </Box>
      <Button
        id={props.id || ''}
        style={{ marginLeft: '24px', marginTop: '-5px', width: '112px', height: '41px' }}
        className={props.className}
        onClick={_toggleAudioEnabled}
        disabled={!props.audioTrack || props.disabled}
        startIcon={isTrackEnabled ? <MicIcon /> : <MicOffIcon />}
        data-cy-audio-toggle
      >
        {!props.audioTrack ? 'No Audio' : isTrackEnabled ? '音声ON' : '音声OFF'}
      </Button>
    </Box>
  );
};

export default React.memo(OppositeAudioGain);
