import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  AudioTrack,
  LocalVideoTrack,
  LocalAudioTrack,
  Participant,
  //RemoteAudioTrack,
  RemoteVideoTrack,
  RemoteAudioTrack,
} from 'twilio-video';

import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import AvatarIcon from '../../icons/AvatarIcon';
import NetworkQualityLevel from '../NetworkQualityLevel/NetworkQualityLevel';
import PinIcon from './PinIcon/PinIcon';
import ScreenShareIcon from '../../icons/ScreenShareIcon';
import Typography from '@material-ui/core/Typography';

import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublications from '../../hooks/usePublications/usePublications';
import useTrack from '../../hooks/useTrack/useTrack';
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';
import { PARTICIPANT_USE_AVATAR, ZINDEX } from '../../constants';
import { useAppState } from '../../state';
//import StandBy from '../../assets/stand-by.png';
import { Box, Menu, MenuItem } from '@material-ui/core';
import Dvr from '../../icons/dvr';
import OppositeDeviceSelectionDialog from '../oppositeDeviceSelectionDialog/oppositeDeviceSelectionDialog';
import useRemoteControl from '../../hooks/useRemoteControl/useRemoteControl';
import { Block, Check } from '@material-ui/icons';
import useDevices from '../../hooks/useDevices/useDevices';
import { SELECTED_VIDEO_INPUT_KEY, DEFAULT_VIDEO_CONSTRAINTS, SELECTED_AUDIO_INPUT_KEY } from '../../constants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useStateEx from '../../hooks/useStateEx/useStateEx';
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useIsTrackEnabled from '../../hooks/useIsTrackEnabled/useIsTrackEnabled';
import { useNativeApi } from '../../utils/nativeApi/useNativeApi';
import { SetMuteRequestData } from '../../utils/nativeApi/nativeApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: 0,
      overflow: 'hidden',
      marginBottom: '0.5em',
      '& video': {
        filter: 'none',
        // objectFit: 'contain !important', // 元はこれです。
        objectFit: 'cover !important', // 修正
      },
      borderRadius: '4px',
      border: `${theme.participantBorderWidth}px solid rgb(245, 248, 255)`,
      paddingTop: `calc(${(9 / 16) * 100}% - ${theme.participantBorderWidth}px)`,
      background: 'black',
      [theme.breakpoints.down('sm')]: {
        height: theme.sidebarMobileHeight,
        width: `${(theme.sidebarMobileHeight * 16) / 9}px`,
        marginRight: '8px',
        marginBottom: '0',
        fontSize: '10px',
        paddingTop: `${theme.sidebarMobileHeight - 2}px`,
      },
    },
    innerContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    infoContainer: {
      position: 'absolute',
      zIndex: ZINDEX.INFO_CONTAINER,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      width: '100%',
      background: 'transparent',
      top: 0,
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'black',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: ZINDEX.AVATAR_CONTAINER,
      [theme.breakpoints.down('sm')]: {
        '& svg': {
          transform: 'scale(0.7)',
        },
      },
    },
    reconnectingContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(40, 42, 43, 0.75)',
      zIndex: ZINDEX.RECONNECTING_CONTAINER,
    },
    screenShareIconContainer: {
      background: 'rgba(0, 0, 0, 0.5)',
      padding: '0.18em 0.3em',
      marginRight: '0.3em',
      display: 'flex',
      '& path': {
        fill: 'white',
      },
    },
    identity: {
      background: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      padding: '0.18em 0.3em',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
    },
    infoRowBottom: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      bottom: 0,
      left: 0,
    },
    typeography: {
      color: 'white',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    hideParticipant: {
      display: 'none',
    },
    cursorPointer: {
      cursor: 'pointer',
    },
  })
);

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
}

interface Window {
  addEventListener: (
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions | undefined
  ) => void;
  removeEventListener: (
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions | undefined
  ) => void;
  dispatchEvent: (event: Event) => boolean;
  localStorage: Storage;
  // eslint-disable-next-line
  alert: (message?: any) => void;
}
declare let window: Window;

export default function ParticipantInfo({
  participant,
  onClick,
  isSelected,
  children,
  isLocalParticipant,
  hideParticipant,
}: ParticipantInfoProps) {
  const baseRef = useRef(null);
  const publications = usePublications(participant);
  const { isStandBy, isMaster, masterIs, whoIs, activeSinkId, setActiveSinkId, setAudioBlocking } = useAppState();
  const {
    openOppositeDeviceSelectionDialog,
    closeOppositeDeviceSelectionDialog,
    replyOppositeVideoInputDevices,
    replyOppositeAudioInputDevices,
    replyOppositeAudioOutputDevices,
    requestYourIsPatient,
  } = useRemoteControl();
  const { videoInputDevices, audioInputDevices, audioOutputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localVideoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack | undefined;
  const localAudioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);

  const audioPublication = publications.find(p => p.kind === 'audio');
  const videoPublication = publications.find(p => p.trackName.includes('camera'));

  const isVideoEnabled = Boolean(videoPublication);
  //const isAudioEnabled = Boolean(audioPublication);
  const isScreenShareEnabled = publications.find(p => p.trackName.includes('screen'));

  const videoTrack = useTrack(videoPublication);
  const audioTrack = useTrack(audioPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);
  //const isAudioSwitchedOff = useIsTrackSwitchedOff(audioTrack as LocalAudioTrack | RemoteAudioTrack);
  const isAudioEnabled = useIsTrackEnabled(audioTrack as LocalAudioTrack | RemoteAudioTrack);

  //const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const [nativeApi] = useNativeApi();

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [openAVControlPane, setOpenAVControlPane] = useState(false);
  const [isStandByMaster, setIsStandByMaster] = useState(true);
  const [showPermission, setShowPermission, getShowPermission] = useStateEx(true);

  // eslint-disable-next-line
  const [cachedVideoInputDevices, setCachedVideoInputDevices, getCachedVideoInputDevices] = useStateEx<object[]>([]);
  // eslint-disable-next-line
  const [cachedAudioInputDevices, setCachedAudioInputDevices, getCachedAudioInputDevices] = useStateEx<object[]>([]);
  // eslint-disable-next-line
  const [cachedAudioOutputDevices, setCachedAudioOutputDevices, getCachedAudioOutputDevices] = useStateEx<object[]>([]);
  // eslint-disable-next-line
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId, getStoredLocalVideoDeviceId] = useStateEx(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
  );
  // eslint-disable-next-line
  const [storedLocalAudioDeviceId, setStoredLocalAudioDeviceId, getStoredLocalAudioDeviceId] = useStateEx(
    window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY)
  );

  console.log('Component:ParticipantInfo', {
    audioPublication,
    videoPublication,
    isVideoEnabled,
    isScreenShareEnabled,
    videoTrack,
    isVideoSwitchedOff,
    audioTrack,
    isParticipantReconnecting,
    classes,
  });

  const [isPatient, setIsPatient] = useState(false);

  useEffect(() => {
    setAudioBlocking(true);
    setTimeout(() => {
      requestYourIsPatient(participant.identity, _getIsPatient);
    }, 1000);
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const _getIsPatient = (value: any) => {
    if ((value?.params?.name ?? '') === participant.identity) {
      const isPatient_ = value?.params?.isPatient ?? false;
      setIsPatient(isPatient_);
    }
  };

  useEffect(() => {
    window.addEventListener('getOppositeVideoInputDevices', _getOppositeVideoInputDevices);
    window.addEventListener('getOppositeAudioInputDevices', _getOppositeAudioInputDevices);
    window.addEventListener('getOppositeAudioOutputDevices', _getOppositeAudioOutputDevices);
    window.addEventListener('replaceOppositeVideoTrack', _replaceOppositeVideoTrack);
    window.addEventListener('replaceOppositeAudioTrack', _replaceOppositeAudioTrack);
    window.addEventListener('replaceOppositeActiveSink', _replaceOppositeActiveSink);
    window.addEventListener('responseStatus', _responseStatus);
    window.addEventListener('queryParticipantStandBy', _queryParticipantStandBy);
    window.addEventListener('setOutputMute', _setOutputMute);
    return () => {
      window.removeEventListener('getOppositeVideoInputDevices', _getOppositeVideoInputDevices);
      window.removeEventListener('getOppositeAudioInputDevices', _getOppositeAudioInputDevices);
      window.removeEventListener('getOppositeAudioOutputDevices', _getOppositeAudioOutputDevices);
      window.removeEventListener('replaceOppositeVideoTrack', _replaceOppositeVideoTrack);
      window.removeEventListener('replaceOppositeAudioTrack', _replaceOppositeAudioTrack);
      window.removeEventListener('replaceOppositeActiveSink', _replaceOppositeActiveSink);
      window.removeEventListener('responseStatus', _responseStatus);
      window.removeEventListener('queryParticipantStandBy', _queryParticipantStandBy);
      window.removeEventListener('setOutputMute', _setOutputMute);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCachedVideoInputDevices(videoInputDevices);
    // eslint-disable-next-line
  }, [videoInputDevices]);

  useEffect(() => {
    setCachedAudioInputDevices(audioInputDevices);
    // eslint-disable-next-line
  }, [audioInputDevices]);

  useEffect(() => {
    setCachedAudioOutputDevices(audioOutputDevices);
    // eslint-disable-next-line
  }, [audioOutputDevices]);

  // eslint-disable-next-line
  const _queryParticipantStandBy = async (e: any) => {
    if (e && participant.identity !== masterIs) {
      //const isStandBy_ = await getShowPermission();
      const isStandBy_ = masterIs !== participant.identity && isStandByMaster;
      const participantStandByEvent = new CustomEvent('participantStandBy', {
        detail: { name: participant.identity, isStandBy: isStandBy_ },
      });
      window.dispatchEvent(participantStandByEvent);
    }
  };

  const _getOppositeVideoInputDevices = () => {
    const reply: object[] = [];
    // eslint-disable-next-line
    getCachedVideoInputDevices().then(async (cachedVideoInputDevices_: any) => {
      for (const device of cachedVideoInputDevices_) {
        const info: object = {
          // eslint-disable-next-line
          deviceId: (device as any).deviceId,
          // eslint-disable-next-line
          label: (device as any).label,
        };
        reply.push(info);
      }
      const localVideoInputDeviceId = /*mediaStreamTrack?.getSettings().deviceId; ||*/ await getStoredLocalVideoDeviceId();
      replyOppositeVideoInputDevices(reply, localVideoInputDeviceId ?? '');
    });
  };

  const _getOppositeAudioInputDevices = () => {
    const reply: object[] = [];
    // eslint-disable-next-line
    getCachedAudioInputDevices().then(async (cachedAudioInputDevices_: any) => {
      for (const device of cachedAudioInputDevices_) {
        const info: object = {
          // eslint-disable-next-line
          deviceId: (device as any).deviceId,
          // eslint-disable-next-line
          label: (device as any).label,
        };
        reply.push(info);
      }

      const srcMediaStreamTrack = localAudioTrack?.noiseCancellation?.sourceTrack;
      // 初期デバイス設定
      let localAudioInputActiveDeviceId =
        localAudioTrack.mediaStreamTrack.getSettings().deviceId ||
        mediaStreamTrack?.getSettings().deviceId ||
        srcMediaStreamTrack?.getSettings().deviceId;

      var userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf('chrome') != -1) {
        localAudioInputActiveDeviceId =
          srcMediaStreamTrack?.getSettings().deviceId || mediaStreamTrack?.getSettings().deviceId;
      } else if (userAgent.indexOf('safari') != -1) {
        localAudioInputActiveDeviceId = localAudioTrack.mediaStreamTrack.getSettings().deviceId;
      }

      // const localAudioInputActiveDeviceId = await getStoredLocalAudioDeviceId();
      replyOppositeAudioInputDevices(reply, localAudioInputActiveDeviceId ?? '');
    });
  };

  const _getOppositeAudioOutputDevices = () => {
    const reply: object[] = [];
    // eslint-disable-next-line
    getCachedAudioOutputDevices().then(async (cachedAudioOutputDevices_: any) => {
      for (const device of cachedAudioOutputDevices_) {
        const info: object = {
          // eslint-disable-next-line
          deviceId: (device as any).deviceId,
          // eslint-disable-next-line
          label: (device as any).label,
        };
        reply.push(info);
      }
      const localAudioOutputDeviceId = activeSinkId;
      replyOppositeAudioOutputDevices(reply, localAudioOutputDeviceId ?? '');
    });
  };

  // eslint-disable-next-line
  const _replaceOppositeVideoTrack = (e: any) => {
    if (e && e.detail !== '') {
      replaceVideoTrack(e.detail as string);
    }
  };

  // eslint-disable-next-line
  const _replaceOppositeAudioTrack = (e: any) => {
    if (e && e.detail !== '') {
      replaceAudioTrack(e.detail as string);
    }
  };

  // eslint-disable-next-line
  const _replaceOppositeActiveSink = (e: any) => {
    if (e && e.detail !== '') {
      setActiveSinkId(e.detail as string);
    }
  };

  // eslint-disable-next-line
  const _setOutputMute = (e: any) => {
    if (e && e.detail !== '') {
      const data: SetMuteRequestData = {
        isMute: e.detail,
      };
      nativeApi.setMute(data);
    }
  };

  function replaceVideoTrack(value: string) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    //e.preventDefault();
    const newDeviceId = value;
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    //setNeededWaiting(true);
    setTimeout(() => {
      localVideoTrack
        ?.restart({
          ...(DEFAULT_VIDEO_CONSTRAINTS as object),
          deviceId: { exact: newDeviceId },
        })
        .then(() => {
          //setNeededWaiting(false);
        })
        .catch(e => {
          window.alert(e.toString());
        });
    }, 0);
  }

  function replaceAudioTrack(value: string) {
    const newDeviceId = value;
    setStoredLocalAudioDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    //setNeededWaiting(true);
    setTimeout(() => {
      localAudioTrack
        ?.restart({ deviceId: { exact: newDeviceId } })
        .then(() => {
          //localAudioInputDeviceId =
          //  srcMediaStreamTrack?.getSettings().deviceId || mediaStreamTrack?.getSettings().deviceId;
          //setNeededWaiting(false);
        })
        .catch(e => {
          window.alert(e.toString());
        });
    }, 0);
  }

  // eslint-disable-next-line
  const _responseStatus = (e: any) => {
    if (e && e.detail) {
      const name = e.detail.name;
      if (name === participant.identity && e.detail.isStandBy === false) {
        setIsStandByMaster(e.detail.isStandBy);
        setTimeout(() => {
          setShowPermission(false);
        }, 5000);
      }
    }
  };

  const _onContextMenu = async (e: React.MouseEvent<HTMLElement>) => {
    // eslint-disable-next-line
    const _getIsPatient2 = (value: any) => {
      if ((value?.params?.name ?? '') === participant.identity) {
        const isPatient_ = value?.params?.isPatient ?? false;
        setIsPatient(isPatient_);
        /*if (isPatient_) {
          setAnchorEl(e.currentTarget);
        }*/
      }
    };

    if (isMaster && masterIs === whoIs) {
      e.preventDefault();
      if (isPatient) {
        setAnchorEl(e.currentTarget);
      } else {
        requestYourIsPatient(participant.identity, _getIsPatient2);
        const handle = setInterval(() => {
          if (isPatient !== undefined) {
            clearInterval(handle);
            setAnchorEl(baseRef.current);
          }
        }, 100);
      }
    }
  };

  useEffect(() => {
    console.log('isStandBy: ', isStandBy);
  }, [isStandBy]);

  const _onClose = () => {
    setAnchorEl(null);
  };
  /*const _updateAVInfos = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    _onClose();
  };*/
  const _openAVControlPane = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setOpenAVControlPane(true);
    openOppositeDeviceSelectionDialog(participant.identity);
    _onClose();
  };

  const _onAVControlePaneClose = () => {
    setOpenAVControlPane(false);
    closeOppositeDeviceSelectionDialog(participant.identity);
  };

  const _masterIndicator = () => {
    return (
      masterIs !== participant.identity &&
      (isStandByMaster ? (
        <div
          style={{
            position: 'absolute',
            left: '0px',
            right: '0px',
            top: '0px',
            bottom: '0px',
            backgroundColor: '#1c1c1c',
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: '0px',
              right: '0px',
              bottom: '0px',
              height: '30px',
              backgroundColor: 'rgba(255,255,0,0.5)',
            }}
          >
            <div
              style={{
                color: '#000',
                top: '4px',
                position: 'absolute',
                right: '6px',
                fontWeight: '600',
                fontSize: '15pt',
              }}
            >
              <Block />
            </div>
            <div
              style={{
                color: '#fff',
                top: '3px',
                position: 'absolute',
                right: '7px',
                fontWeight: '600',
                fontSize: '15pt',
              }}
            >
              <Block />
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            position: 'absolute',
            left: '0px',
            right: '0px',
            bottom: '0px',
            height: '30px',
            backgroundColor: showPermission ? 'rgba(0,255,0,0.5)' : 'rgba(0,0,0,0)',
            transition: 'all 0.5s 0s ease',
          }}
        >
          <div
            style={{
              color: showPermission ? '#000' : 'rgba(0,0,0,0)',
              top: '4px',
              position: 'absolute',
              right: '6px',
              fontWeight: '600',
              fontSize: '15pt',
            }}
          >
            <Check />
          </div>
          <div
            style={{
              color: showPermission ? '#fff' : 'rgba(0,0,0,0)',
              top: '3px',
              position: 'absolute',
              right: '7px',
              fontWeight: '600',
              fontSize: '15pt',
            }}
          >
            <Check />
          </div>
        </div>
      ))
    );
  };

  return !isMaster && isStandBy ? (
    <></>
  ) : (
    <div
      ref={baseRef}
      className={clsx(classes.container, {
        [classes.hideParticipant]: hideParticipant,
        [classes.cursorPointer]: Boolean(onClick),
      })}
      /*eslint-disable-next-line*/
      onClick={anchorEl ? () => {} : onClick}
      onContextMenu={_onContextMenu}
      data-cy-participant={participant.identity}
    >
      <div className={classes.infoContainer}>
        <NetworkQualityLevel participant={participant} />
        {isMaster && _masterIndicator()}
        <div className={classes.infoRowBottom}>
          {isScreenShareEnabled && (
            <span className={classes.screenShareIconContainer}>
              <ScreenShareIcon />
            </span>
          )}
          <span className={classes.identity}>
            {/*eslint-disable-next-line*/}
            <AudioLevelIndicator audioTrack={(audioTrack as any) as AudioTrack} />
            <Typography
              variant="body1"
              className={classes.typeography}
              component="span"
              style={{ color: participant.identity === masterIs ? '#64ffff' : 'inherit' }}
            >
              {participant.identity}
              {isLocalParticipant && ' (あなた)'}
            </Typography>
          </span>
        </div>
        <div>{isSelected && <PinIcon />}</div>
      </div>
      <div className={classes.innerContainer}>
        {!isStandBy && PARTICIPANT_USE_AVATAR && (!isVideoEnabled || isVideoSwitchedOff) && (
          <div className={classes.avatarContainer}>
            <AvatarIcon />
          </div>
        )}
        {//isStandBy && participant.identity === masterIs && (
        !isMaster && isStandBy && (
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: '#f8f8f8',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div style={{ margin: 'auto', textAlign: 'center', fontSize: '16pt' }}>しばらくお待ち下さい。</div>
          </div>
        )}
        {!isStandBy && isParticipantReconnecting && (
          <div className={classes.reconnectingContainer}>
            <Typography variant="body1" className={classes.typeography}>
              Reconnecting...
            </Typography>
          </div>
        )}
        {children}
      </div>
      <Menu
        id="context-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={_onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={_openAVControlPane}>
          <Dvr />
          <Box style={{ width: '4px' }} />
          音声映像調整...
        </MenuItem>
      </Menu>
      {openAVControlPane && (
        <OppositeDeviceSelectionDialog
          open={openAVControlPane}
          onClose={_onAVControlePaneClose}
          oppositeName={participant.identity}
          // eslint-disable-next-line
          audioTrack={(audioTrack as any) as AudioTrack}
          isVideoEnabled={isVideoEnabled}
          isVideoSwitchedOff={isVideoSwitchedOff}
          isAudioEnabled={isAudioEnabled}
        />
      )}
    </div>
  );
}
