import React, { useEffect, useState } from 'react';
import { FormControl, MenuItem, Typography, Select, Button } from '@material-ui/core';
import useRemoteControl from '../../../hooks/useRemoteControl/useRemoteControl';
import VideoOffIcon from '../../../icons/VideoOffIcon';
import VideoOnIcon from '../../../icons/VideoOnIcon';

export interface OppositeVideoInputListPropType {
  oppositeName: string;
  //videoTrack: VideoTrack;
  id?: string;
  disabled?: boolean;
  className?: string;
  isVideoEnabled: boolean;
  isVideoSwitchedOff: boolean;
}

const OppositeVideoInputList = (props: OppositeVideoInputListPropType) => {
  const [neededWaiting, setNeededWaiting] = useState(true);
  const { replaceOppositeVideoTrack, getOppositeVideoInputDevices } = useRemoteControl();
  const { toggleCameraBlock } = useRemoteControl();

  const [videoInputDevices, setVideoInputDevices] = useState<object[] | null>(null);
  const [oppositeVideoInputDeviceId, setOppositeVideoInputDeviceId] = useState('');

  const _replaceTrack = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    if (e) {
      replaceOppositeVideoTrack(props.oppositeName, e.target.value as string);
      setOppositeVideoInputDeviceId(e.target.value as string);
    }
  };

  useEffect(() => {
    getOppositeVideoInputDevices(props.oppositeName).then((values: object | null) => {
      if (values) {
        // eslint-disable-next-line
        setVideoInputDevices((values as any).devices);
        // eslint-disable-next-line
        setOppositeVideoInputDeviceId((values as any).idSelected);
        setNeededWaiting(false);
      }
    });
    // eslint-disable-next-line
  }, []);

  const _toggleVideoEnabled = () => {
    toggleCameraBlock(props.oppositeName, !props.isVideoEnabled);
  };

  return (
    <div>
      {videoInputDevices ? (
        <FormControl fullWidth>
          <Typography variant="subtitle2" gutterBottom>
            ビデオ設定
          </Typography>
          <Select
            onChange={_replaceTrack}
            value={oppositeVideoInputDeviceId ?? ''}
            variant="outlined"
            disabled={neededWaiting}
          >
            {videoInputDevices.length === 0 ? (
              <>カメラがありません</>
            ) : (
              videoInputDevices.map(device => (
                // eslint-disable-next-line
                <MenuItem value={(device as any).deviceId} key={(device as any).deviceId}>
                  {/* eslint-disable-next-line*/}
                  {(device as any).label}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      ) : (
        <>
          <Typography variant="subtitle2" gutterBottom>
            ビデオ設定
          </Typography>
          <Typography>取得中...</Typography>
        </>
      )}
      <Button
        id={props.id || ''}
        style={{ marginTop: '4px', marginLeft: '398px' }}
        className={props.className}
        onClick={_toggleVideoEnabled}
        disabled={(videoInputDevices?.length ?? 0) === 0 || props.disabled}
        startIcon={props.isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
      >
        {(videoInputDevices?.length ?? 0) === 0 ? 'No Video' : props.isVideoEnabled ? 'カメラON' : 'カメラOFF'}
      </Button>
    </div>
  );
};

export default React.memo(OppositeVideoInputList);
