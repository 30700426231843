import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';

import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import AvatarIcon from '../../icons/AvatarIcon';
import NetworkQualityLevel from '../NetworkQualityLevel/NetworkQualityLevel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import useIsRecording from '../../hooks/useIsRecording/useIsRecording';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';
import usePublications from '../../hooks/usePublications/usePublications';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useTrack from '../../hooks/useTrack/useTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { ZINDEX } from '../../constants';
import { useAppState } from '../../state';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  identity: {
    background: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: '0.1em 0.3em 0.1em 0',
    display: 'inline-flex',
    '& svg': {
      marginLeft: '0.3em',
    },
    marginRight: '0.4em',
    alignItems: 'center',
    pointerEvents: 'initial',
  },
  infoContainer: {
    position: 'absolute',
    zIndex: ZINDEX.INFO_CONTAINER,
    height: '100%',
    width: '100%',
    pointerEvents: 'none', // 上で表示されても全体的にマウスイベントがないよう。
  },
  reconnectingContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(40, 42, 43, 0.75)',
    zIndex: ZINDEX.RECONNECTING_CONTAINER,
  },
  fullWidth: {
    gridArea: '1 / 1 / 2 / 3',
    height: '100%', // 新規: canvas対応
    [theme.breakpoints.down('sm')]: {
      gridArea: '1 / 1 / 3 / 3',
    },
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'black',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: ZINDEX.AVATAR_CONTAINER,
    '& svg': {
      transform: 'scale(2)',
    },
  },
  recordingIndicator: {
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: '0.1em 0.3em 0.1em 0',
    fontSize: '1.2rem',
    height: '28px',
    [theme.breakpoints.down('sm')]: {
      bottom: 'auto',
      right: 0,
      top: 0,
    },
  },
  circle: {
    height: '12px',
    width: '12px',
    background: 'red',
    borderRadius: '100%',
    margin: '0 0.6em',
    animation: `1.25s $pulsate ease-out infinite`,
  },
  '@keyframes pulsate': {
    '0%': {
      background: `#A90000`,
    },
    '50%': {
      background: '#f00',
    },
    '100%': {
      background: '#A90000',
    },
  },
}));

/*interface Window {
  addEventListener: (
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions | undefined
  ) => void;
  removeEventListener: (
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions | undefined
  ) => void;
  dispatchEvent: (event: Event) => boolean;
  localStorage: Storage;
  // eslint-disable-next-line
  alert: (message?: any) => void;
}
declare let window: Window;*/

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
}

export default function MainParticipantInfo({ participant, children }: MainParticipantInfoProps) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { isMaster, isStandBy, setAudioBlocking } = useAppState();

  const localParticipant = room?.localParticipant;
  const isLocal = localParticipant === participant;

  const screenShareParticipant = useScreenShareParticipant();
  const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;

  const publications = usePublications(participant);
  const videoPublication = publications.find(p => !p.trackName.includes('screen') && p.kind === 'video');
  const screenSharePublication = publications.find(p => p.trackName.includes('screen'));

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoEnabled = Boolean(videoTrack);

  const audioPublication = publications.find(p => p.kind === 'audio');
  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;

  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const isRecording = useIsRecording();

  const paneHeight = () => {
    const pane = document.getElementById('background-pane');
    if (pane) {
      return pane.clientHeight;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const value = !isMaster && isStandBy;
    setAudioBlocking(value);
    // eslint-disable-next-line
  }, [isMaster, isStandBy]);

  const marginTop = (() => {
    return `${paneHeight() / 2 - 130}px`;
  })();

  return !isMaster && isStandBy ? (
    <Box
      style={{
        position: 'fixed',
        left: '0px',
        top: '0px',
        right: '0px',
        bottom: '0px',
        backgroundColor: 'rgb(40,42,43)',
        padding: 'auto',
      }}
      id="background-pane"
    >
      {marginTop !== '-130px' && (
        <div
          style={{
            display: 'table',
            width: '500px',
            height: '260px',
            backgroundColor: '#fff',
            color: '#000',
            textAlign: 'center',
            fontSize: '16pt',
            padding: '24px',
            alignItems: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: `${marginTop}`,
            borderRadius: '8px',
          }}
        >
          <p style={{ fontSize: '18pt', fontWeight: '600', lineHeight: '1.8rem' }}>待合室</p>
          <p style={{ lineHeight: '0.8rem' }}>医師がまだ入室していません。</p>
          <p style={{ lineHeight: '0.8rem' }}>医師が入室して通話が開始されると、</p>
          <p style={{ lineHeight: '0.8rem' }}>自動的に開始されます。</p>
          <p style={{ lineHeight: '0.8rem' }}>しばらくお待ちください。</p>
        </div>
      )}
    </Box>
  ) : (
    <div
      data-cy-main-participant
      data-cy-participant={participant.identity}
      className={clsx(classes.container, {
        [classes.fullWidth]: !isRemoteParticipantScreenSharing,
      })}
    >
      <div className={classes.infoContainer}>
        <div style={{ display: 'flex' }}>
          <div className={classes.identity}>
            <AudioLevelIndicator audioTrack={audioTrack} />
            <Typography variant="body1" style={{ color: isMaster && isLocal ? '#64ffff' : 'inherit' }}>
              {participant.identity}
              {isLocal && ' (あなた)'}
              {screenSharePublication && ' - Screen'}
            </Typography>
          </div>
          <NetworkQualityLevel participant={participant} />
        </div>
        {isRecording && (
          <Tooltip
            title="All participants' audio and video is currently being recorded. Visit the app settings to stop recording."
            placement="top"
          >
            <div className={classes.recordingIndicator}>
              <div className={classes.circle}></div>
              <Typography variant="body1" color="inherit" data-cy-recording-indicator>
                Recording
              </Typography>
            </div>
          </Tooltip>
        )}
      </div>
      {(!isVideoEnabled || isVideoSwitchedOff) && (
        <div className={classes.avatarContainer}>
          <AvatarIcon />
        </div>
      )}
      {isParticipantReconnecting && (
        <div className={classes.reconnectingContainer}>
          <Typography variant="body1" style={{ color: 'white' }}>
            Reconnecting...
          </Typography>
        </div>
      )}
      {children}
    </div>
  );
}
