import { getMobileOS } from './uaAnalyzer';

declare global {
  interface Window {
    request: any;
    response: any;
    webkit: any;
    handlers: { [key: number]: NativeApiManagerHandler<any> };
  }
}
declare const request: any;

type NativeApiManagerCallback<T> = ((data: T) => void) | undefined;
type NativeApiManagerHandler<T> = {
  method: string;
  callback: NativeApiManagerCallback<T>;
};
type NativeApiManagerMessage = {
  hash?: number;
  [key: string]: any;
};

// ネイティブからのレスポンス受け取り
// thisがbindされないためwindowオブジェクトにhandlersをおく
// さらに二重初期化をふせぐため関数自体をclass外へ
function response(json: string) {
  const data: NativeApiManagerMessage = JSON.parse(json);
  console.log('NativeApiManager.response', data);
  console.log(JSON.stringify(data));

  // コールバック関数を取り出して実行
  if (data.hash) {
    // ネイティブにhash実装がある場合
    const handler = window.handlers[data.hash];
    delete window.handlers[data.hash];
    if (handler.callback) {
      handler.callback(data[handler.method]);
    }
  } else {
    // ネイティブにhash実装がない場合呼び出しmethodとレスポンスmethodが一致するものを返す
    console.log('search handler');
    for (let hash in window.handlers) {
      const handler = window.handlers[hash];
      console.log('search handler method');
      console.log(handler.method);
      console.log(data[handler.method]);
      if (data[handler.method]) {
        console.log('found data for given method');
        if (handler.callback) {
          console.log('found callback for given method');
          handler.callback(data[handler.method]);
        }
        delete window.handlers[hash];
      }
    }
  }
}

export default class NativeApiManager {
  init() {
    // ネイティブから呼び出された場合thisがbindできないようでwindowオブジェクトにhandlersをおく
    // 既に別インスタンスで初期化済みなら初期化しない
    if (window.response !== response) {
      console.log('nativeApiManager init global');
      window.handlers = {};
      window.response = response;
    }
  }

  // ネイティブへのデータポスト
  post<T>(method: string, payload?: any) {
    return new Promise<T>((resolve, reject) => {
      try {
        this.postSync<T>(method, payload, resolve);
      } catch (error) {
        reject(error);
      }
    });
  }
  // ネイティブへのデータポスト
  postSync<T>(method: string, payload?: any, callback?: NativeApiManagerCallback<T>) {
    const hash = new Date().getTime();
    // コールバック関数をhashをキーに保持
    if (callback) {
      window.handlers[hash] = {
        method: method,
        callback: callback,
      };
    }
    console.log(window.handlers);
    const data: NativeApiManagerMessage = { hash: hash };
    data[method] = payload ? payload : null;
    const json = JSON.stringify(data);
    console.log('nativeApiManager postMessage', data, json);
    console.log(json);
    const agent = getMobileOS(navigator.userAgent);
    switch (agent) {
      case 'android':
        request.postMessage(json);
        break;
      case 'ios':
        window.webkit.messageHandlers.request.postMessage(json);
        break;
      default:
        // echoにしておく
        // dev server 向けモックにしてもよい
        if (callback) {
          response(json);
        }
        break;
    }
  }
}
