import axios from 'axios';
import { WEB_API_HOST } from '../constants';

export interface ChatEnterResponse {
  sessionId: string;
  roomId: string;
  logs: string[];
}

export const requestChatEnter = async (roomId: string, sessionId: string): Promise<ChatEnterResponse> => {
  const filename = process.env.REACT_APP_FILE_NAME;
  console.log(filename);
  const url = WEB_API_HOST + '/d2d/chat/enter';
  const response = await axios.post(url, { roomId, sessionId });
  return response.data;
};

// 旧v2エンドポイントへポストしてそこからWebSocketのイベントを飛ばしているのでとりあえず生かしておく
// 将来的にはPusher入れ替え
export const requestChatSend = async (
  roomId: string,
  sessionId: string,
  author: string,
  body: string,
  type: string
): Promise<void> => {
  let staff_id = localStorage.getItem('staffId');
  let reservation_room_id = localStorage.getItem('roomName');
  const url = WEB_API_HOST + '/d2d/chat/send';
  await axios.post(url, { roomId, sessionId, author, body, type, staff_id, reservation_room_id });
};

export const requestChatLeave = async (roomId: string, sessionId: string): Promise<void> => {
  const url = WEB_API_HOST + '/d2d/chat/leave';
  await axios.post(url, { roomId, sessionId });
};

// FSM api 実装
// d2dではroom_idがstaticな為room作成プロセスが不要
// もっと汎用化するならroom作成プロセスが必要だがMemo機能との絡みがちょっと複雑に
interface FSMMessage {
  member_alias: string;
  member_type: number;
  type: 'text' | 'media' | 'memo';
  text: string;
  data?: string;
}

// メッセージ送信
export const sendMessage = (room_id: string, message: FSMMessage, authorized_key: string, app_id: string) => {
  return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/fsm/rooms/${room_id}/messages`, message, {
    headers: { 'authorized-key': authorized_key, 'app-id': app_id },
    responseType: 'blob',
  });
};

// メッセージ取得
// type===memoはクライアント側で非表示制御
export const getMessages = (room_id: string, from: string, authorized_key: string, app_id: string) => {
  return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/fsm/rooms/${room_id}/messages?read_at=${from}`, {
    headers: { 'authorized-key': authorized_key, 'app-id': app_id },
  });
};
