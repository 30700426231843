import { useCallback, useEffect } from 'react';
import { useAppState } from '../../state';
import useVideoContext from '../useVideoContext/useVideoContext';
import { LocalDataTrack } from 'twilio-video';
import useStateEx from '../useStateEx/useStateEx';
import useStrokeState from '../useStrokeState/useStrokeState';

interface CallbackInfo {
  [id: string]: (value: object) => void;
}

interface Window {
  dispatchEvent: (event: Event) => boolean;
  requestYourIsPatientCallbackQueue: CallbackInfo;
}
declare let window: Window;

export interface ToggleStandByButtonProps {
  id?: string;
  disabled?: boolean;
  className?: string;
}

const useRemoteControl = () => {
  const { localTracks, room } = useVideoContext();
  const { setIsStandBy, getIsStandBy, whoIs, isStandBy, isMaster, isPatient, setAudioOutputBlocking } = useAppState();
  // eslint-disable-next-line
  const [answerIsMaster, setAnswerIsMaster, getAnswerIsMaster] = useStateEx<object | null>(null);
  const { uuid } = useStrokeState();

  const openOppositeDeviceSelectionDialogEvent = new CustomEvent('openOppositeDeviceSelectionDialog');
  const closeOppositeDeviceSelectionDialogEvent = new CustomEvent('closeOppositeDeviceSelectionDialog');
  const getOppositeVideoInputDevicesEvent = new CustomEvent('getOppositeVideoInputDevices');
  const getOppositeAudioInputDevicesEvent = new CustomEvent('getOppositeAudioInputDevices');
  const getOppositeAudioOutputDevicesEvent = new CustomEvent('getOppositeAudioOutputDevices');

  const onTrackMessage = useCallback(
    // eslint-disable-next-line
    (data: string | ArrayBuffer, track: any, participant: any) => {
      track;
      participant;
      if (typeof data === 'string') {
        const values = JSON.parse(data);
        if (values.method === 'audioVideoRemoteControl') {
          const params = values.params;
          if (values.function === 'openOppositeDeviceSelectionDialog') {
            if (params.oppositeName === whoIs) {
              window.dispatchEvent(openOppositeDeviceSelectionDialogEvent);
            }
          } else if (values.function === 'closeOppositeDeviceSelectionDialog') {
            if (params.oppositeName === whoIs) {
              window.dispatchEvent(closeOppositeDeviceSelectionDialogEvent);
            }
          } else if (values.function === 'getOppositeVideoInputDevices') {
            if (params.oppositeName === whoIs) {
              window.dispatchEvent(getOppositeVideoInputDevicesEvent);
            }
          } else if (values.function === 'getOppositeAudioInputDevices') {
            if (params.oppositeName === whoIs) {
              window.dispatchEvent(getOppositeAudioInputDevicesEvent);
            }
          } else if (values.function === 'getOppositeAudioOutputDevices') {
            if (params.oppositeName === whoIs) {
              window.dispatchEvent(getOppositeAudioOutputDevicesEvent);
            }
          } else if (values.function === 'requestYourIsPatient') {
            if (params.oppositeName === whoIs) {
              _replyYourIsPatient(values.id);
            }
          } else if (values.function === 'replyYourIsPatient') {
            // masterが受け取る
            if (isMaster) {
              const callback = window.requestYourIsPatientCallbackQueue[values.id];
              if (callback) {
                callback(values);
                delete window.requestYourIsPatientCallbackQueue[values.id];
              }
            }
          } else if (values.function === 'replyOppositeVideoDevices') {
            // masterが受け取る
            if (isMaster) {
              setOppositeVideoInputDevices(params);
            }
          } else if (values.function === 'replyOppositeAudioInputDevices') {
            // masterが受け取る
            if (isMaster) {
              setOppositeAudioInputDevices(params);
            }
          } else if (values.function === 'replyOppositeAudioOutputDevices') {
            // masterが受け取る
            if (isMaster) {
              setOppositeAudioOutputDevices(params);
            }
          } else if (values.function === 'replaceOppositeVideoTrack') {
            const replaceOppositeVideoTrackEvent = new CustomEvent('replaceOppositeVideoTrack', {
              detail: params.deviceId,
            });
            if (params.oppositeName === whoIs) {
              window.dispatchEvent(replaceOppositeVideoTrackEvent);
            }
          } else if (values.function === 'replaceOppositeAudioTrack') {
            const replaceOppositeAudioTrackEvent = new CustomEvent('replaceOppositeAudioTrack', {
              detail: params.deviceId,
            });
            if (params.oppositeName === whoIs) {
              window.dispatchEvent(replaceOppositeAudioTrackEvent);
            }
          } else if (values.function === 'replaceOppositeActiveSink') {
            const replaceOppositeActiveSinkEvent = new CustomEvent('replaceOppositeActiveSink', {
              detail: params.deviceId,
            });
            if (params.oppositeName === whoIs) {
              window.dispatchEvent(replaceOppositeActiveSinkEvent);
            }
          } else if (values.function === 'closeAll') {
            if (params.masterName !== whoIs) {
              const endCallButton = new CustomEvent('closeAll', {
                detail: { closeAll: params.closeAll },
              });
              const menuConnected = document.getElementById('end-call-button');
              menuConnected?.dispatchEvent(endCallButton);
            }
          } else if (values.function === 'requestStatus') {
            if (whoIs && params.name !== whoIs && !isMaster) {
              getIsStandBy().then(isStandBy_ => {
                _responseStatus(whoIs, isStandBy_);
              });
            }
          } else if (values.function === 'responseStatus') {
            const responseStatusEvent = new CustomEvent('responseStatus', {
              detail: { name: params.name, isStandBy: params.permit },
            });
            if (params.name !== whoIs) {
              window.dispatchEvent(responseStatusEvent);
            }
          } else if (values.function === 'setPermission') {
            if (isStandBy) {
              if (whoIs && whoIs === params.name) {
                setIsStandBy(true);
                // 状態変更を応答する
                _responseStatus(whoIs, true);
              }
            }
          } else if (values.function === 'clearPermission') {
            if (isStandBy) {
              if (whoIs && whoIs === params.name) {
                setIsStandBy(false);
                // 状態変更を応答する
                _responseStatus(whoIs, false);
              }
            }
          } else if (values.function === 'changeOppositeAudioInputGainValue') {
            if (whoIs && params.name === whoIs) {
              const changeOppositeAudioInputGainValueEvent = new CustomEvent('changeOppositeAudioInputGainValue', {
                detail: params.value as number,
              });
              window.dispatchEvent(changeOppositeAudioInputGainValueEvent);
            }
          } else if (values.function === 'toggleMute') {
            if (whoIs && params.name === whoIs) {
              const changeOppositeAudioInputEnableEvent = new CustomEvent('changeOppositeAudioInputEnable', {
                detail: params.enable as number,
              });
              window.dispatchEvent(changeOppositeAudioInputEnableEvent);
            }
          } else if (values.function === 'toggleCameraBlock') {
            if (whoIs && params.name === whoIs) {
              const changeOppositeVideoInputEnableEvent = new CustomEvent('changeOppositeVideoInputEnable', {
                detail: params.enable as number,
              });
              window.dispatchEvent(changeOppositeVideoInputEnableEvent);
            }
          } else if (values.function === 'setSpeakerMute') {
            if (whoIs && params.name === whoIs) {
              setAudioOutputBlocking(true);
            }
          } else if (values.function === 'clearSpeakerMute') {
            if (whoIs && params.name === whoIs) {
              setAudioOutputBlocking(false);
            }
          } else if (values.function === 'setOutputMute') {
            console.log(params);
            if (whoIs && params.name === whoIs) {
              const setOutputMute = new CustomEvent('setOutputMute', {
                detail: params.mute as string,
              });
              window.dispatchEvent(setOutputMute);
            }
          }
        }
      }
    },
    // eslint-disable-next-line
    [whoIs]
  );

  useEffect(() => {
    window.requestYourIsPatientCallbackQueue = {};
  }, []);

  useEffect(() => {
    let set = false;
    if (!room) {
      console.debug('No room');
      return;
    }
    room.on('trackMessage', onTrackMessage);
    set = true;
    return () => {
      if (set) {
        room.off('trackMessage', onTrackMessage);
      }
    };
  }, [onTrackMessage, room]);

  const _transmit = (value: string) => {
    const temp = localTracks.filter(t => t.name === 'data')[0] as LocalDataTrack | undefined;
    if (temp && value) {
      temp.send(value);
      //console.log(retVal);
    }
  };

  // 音声映像設定情報をマスターだげが要求
  const broadcastQueryAudioVideoSettings = async () => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'queryAudioVideoSettings',
      params: {},
    };
    const data = JSON.stringify(values);
    _transmit(data);
  };

  const queryIsStandBy = async () => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'queryIsStandBy',
      params: {
        requester: whoIs,
      },
    };
    const data = JSON.stringify(values);
    let count = 0;
    const handle = setInterval(() => {
      _transmit(data);
      if (++count === 1) {
        clearInterval(handle);
      }
    }, 333);
    //_transmit(data);
    return new Promise<object | null>(resolve => {
      let count = 0;
      const handle2 = setInterval(async () => {
        const answerIsMaster_ = await getAnswerIsMaster();
        if (answerIsMaster_) {
          setAnswerIsMaster(null);
          clearInterval(handle);
          clearInterval(handle2);
          resolve(answerIsMaster_);
        }
        // リロードのときは1秒以上かかる
        if (++count === 60) {
          clearInterval(handle2);
          resolve(null);
        }
      }, 33);
    });
  };

  const openOppositeDeviceSelectionDialog = (oppositeName: string) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'openOppositeDeviceSelectionDialog',
      params: {
        oppositeName: oppositeName,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
  };

  const closeOppositeDeviceSelectionDialog = (oppositeName: string) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'closeOppositeDeviceSelectionDialog',
      params: {
        oppositeName: oppositeName,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
  };

  const [oppositeVideoInputDevices, setOppositeVideoInputDevices, _getOppositeVideoInputDevices] = useStateEx<
    object | null
  >(null);
  const getOppositeVideoInputDevices = (oppositeName: string) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'getOppositeVideoInputDevices',
      params: {
        oppositeName: oppositeName,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
    return new Promise<object | null>(resolve => {
      let count = 0;
      const handle2 = setInterval(async () => {
        const oppositeVideoInputDevices_ = await _getOppositeVideoInputDevices();
        if (oppositeVideoInputDevices_) {
          clearInterval(handle2);
          setOppositeVideoInputDevices(null);
          resolve(oppositeVideoInputDevices_ ? oppositeVideoInputDevices_ : null);
        }
        if (++count === 30) {
          clearInterval(handle2);
          setOppositeVideoInputDevices(null);
          resolve(null);
        }
      }, 100);
    });
  };

  // eslint-disable-next-line
  const [oppositeAudioInputDevices, setOppositeAudioInputDevices, _getOppositeAudioInputDevices] = useStateEx<
    object | null
  >(null);
  const getOppositeAudioInputDevices = (oppositeName: string) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'getOppositeAudioInputDevices',
      params: {
        oppositeName: oppositeName,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
    return new Promise<object | null>(resolve => {
      let count = 0;
      const handle2 = setInterval(async () => {
        const oppositeAudioInputDevices_ = await _getOppositeAudioInputDevices();
        if (oppositeAudioInputDevices_) {
          clearInterval(handle2);
          setOppositeAudioInputDevices(null);
          resolve(oppositeAudioInputDevices_ ? oppositeAudioInputDevices_ : null);
        }
        if (++count === 30) {
          clearInterval(handle2);
          setOppositeAudioInputDevices(null);
          resolve(null);
        }
      }, 100);
    });
  };

  // eslint-disable-next-line
  const [oppositeAudioOutputDevices, setOppositeAudioOutputDevices, _getOppositeAudioOutputDevices] = useStateEx<
    object | null
  >(null);
  const getOppositeAudioOutputDevices = (oppositeName: string) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'getOppositeAudioOutputDevices',
      params: {
        oppositeName: oppositeName,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
    return new Promise<object | null>(resolve => {
      let count = 0;
      const handle2 = setInterval(async () => {
        const oppositeAudioOutputDevices_ = await _getOppositeAudioOutputDevices();
        if (oppositeAudioOutputDevices_) {
          clearInterval(handle2);
          setOppositeAudioOutputDevices(null);
          resolve(oppositeAudioOutputDevices_ ? oppositeAudioOutputDevices_ : null);
        }
        if (++count === 30) {
          clearInterval(handle2);
          setOppositeAudioOutputDevices(null);
          resolve(null);
        }
      }, 100);
    });
  };

  const replyOppositeVideoInputDevices = (devices: object, idSelected: string) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'replyOppositeVideoDevices',
      params: {
        devices: devices,
        idSelected: idSelected,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
  };

  const replyOppositeAudioInputDevices = (devices: object, idSelected: string) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'replyOppositeAudioInputDevices',
      params: {
        devices: devices,
        idSelected: idSelected,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
  };

  const replyOppositeAudioOutputDevices = (devices: object, idSelected: string) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'replyOppositeAudioOutputDevices',
      params: {
        devices: devices,
        idSelected: idSelected,
        isIpad: navigator.userAgent.includes('Macintosh') && 'ontouchend' in document,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
  };

  const replaceOppositeVideoTrack = (oppositeName: string, deviceId: string) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'replaceOppositeVideoTrack',
      params: {
        oppositeName: oppositeName,
        deviceId: deviceId,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
  };

  const replaceOppositeAudioTrack = (oppositeName: string, deviceId: string) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'replaceOppositeAudioTrack',
      params: {
        oppositeName: oppositeName,
        deviceId: deviceId,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
  };

  const replaceOppositeActiveSink = (oppositeName: string, deviceId: string) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'replaceOppositeActiveSink',
      params: {
        oppositeName: oppositeName,
        deviceId: deviceId,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
  };

  const broadcastCloseAll = (value: boolean) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'closeAll',
      params: {
        masterName: whoIs,
        closeAll: value,
      },
    };
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      const data = JSON.stringify(values);
      _transmit(data);
      resolve(true);
      /*const data = JSON.stringify(values);
      let count = 0;
      const handle = setInterval(() => {
        _transmit(data);
        if (++count === 2) {
          clearInterval(handle);
          resolve(true);
        }
      }, 333);*/
    });
  };

  const broadCastRequestStatus = (name: string, isMaster: boolean) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'requestStatus',
      params: {
        name: name,
        isMaster: isMaster,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
  };

  const setPermission = (name: string) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'setPermission',
      params: {
        name: name,
        clear: true,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
  };

  const clearPermission = (name: string) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'clearPermission',
      params: {
        name: name,
        clear: true,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
  };

  const _responseStatus = (name: string, isStandBy_: boolean) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'responseStatus',
      params: {
        name: name,
        permit: isStandBy_,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
  };

  const requestChangeOppositeAudioInputGainValue = (name: string, value: number) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'changeOppositeAudioInputGainValue',
      params: {
        name: name,
        value: value,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
  };

  // eslint-disable-next-line
  //const [replyYourIsPatient, setReplyYourIsPatient, _getReplyYourIsPatient] = useStateEx<IsPatient | null>(null);
  // eslint-disable-next-line
  const requestYourIsPatient = (oppositeName: string, callback: (value: object) => void) => {
    const id = uuid();
    const value = {
      id: id,
      method: 'audioVideoRemoteControl',
      function: 'requestYourIsPatient',
      params: {
        oppositeName: oppositeName,
      },
    };
    window.requestYourIsPatientCallbackQueue[id] = callback;
    const data = JSON.stringify(value);
    _transmit(data);
    // eslint-disable-next-line
    /*return new Promise<any>(resolve => {
      let count = 0;
      const handle2 = setInterval(async () => {
        if (window.isPatients[oppositeName]) {
          clearInterval(handle2);
          setOppositeAudioOutputDevices(null);
          resolve({ name: oppositeName, isPatient: window.isPatients[oppositeName] });
        }
        if (++count === 30) {
          clearInterval(handle2);
          setOppositeAudioOutputDevices(null);
          resolve(false);
        }
      }, 100);
    });*/
  };

  const _replyYourIsPatient = (transactionId: string) => {
    const values = {
      id: transactionId,
      method: 'audioVideoRemoteControl',
      function: 'replyYourIsPatient',
      params: {
        name: whoIs,
        isPatient: isPatient,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
  };

  const toggleMute = (oppositeName: string, enable: boolean) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'toggleMute',
      params: {
        name: oppositeName,
        enable: enable,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
  };

  const toggleCameraBlock = (oppositeName: string, enable: boolean) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'toggleCameraBlock',
      params: {
        name: oppositeName,
        enable: enable,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
  };

  const setSpeakerMute = (oppositeName: string) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'setSpeakerMute',
      params: {
        name: oppositeName,
        enable: true,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
  };

  const clearSpeakerMute = (oppositeName: string) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'clearSpeakerMute',
      params: {
        name: oppositeName,
        enable: true,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
  };

  const setOutputMute = (oppositeName: string, mute: boolean) => {
    const values = {
      method: 'audioVideoRemoteControl',
      function: 'setOutputMute',
      params: {
        name: oppositeName,
        mute: mute,
      },
    };
    const data = JSON.stringify(values);
    _transmit(data);
  };

  return {
    //broadcastIsMaster,
    //broadcastIsStandBy,
    oppositeVideoInputDevices,
    queryIsStandBy,
    broadcastQueryAudioVideoSettings,
    //broadcastAudioVideoInfo,
    openOppositeDeviceSelectionDialog,
    closeOppositeDeviceSelectionDialog,
    getOppositeVideoInputDevices,
    getOppositeAudioInputDevices,
    getOppositeAudioOutputDevices,
    replyOppositeVideoInputDevices,
    replyOppositeAudioInputDevices,
    replyOppositeAudioOutputDevices,
    replaceOppositeVideoTrack,
    replaceOppositeAudioTrack,
    replaceOppositeActiveSink,
    broadcastCloseAll,
    broadCastRequestStatus,
    setPermission,
    clearPermission,
    requestChangeOppositeAudioInputGainValue,
    requestYourIsPatient,
    toggleMute,
    toggleCameraBlock,
    setSpeakerMute,
    clearSpeakerMute,
    setOutputMute,
  };
};

export default useRemoteControl;
