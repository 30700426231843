import React, { useEffect, useState } from 'react';
import { FormControl, MenuItem, Typography, Select, Button } from '@material-ui/core';
import useRemoteControl from '../../../hooks/useRemoteControl/useRemoteControl';
import SpeakerIcon from '../../../icons/speakerIcon';
import SpeakerOffIcon from '../../../icons/speakerOffIcon';
import { Status } from '../../Buttons/toggleStandByButton/toggleStandByButton';
// import { useAppState } from '../../../state';

interface Window {
  statuses: Status;
  //addEventListener: (
  //  type: string,
  //  listener: EventListenerOrEventListenerObject,
  //  options?: boolean | AddEventListenerOptions | undefined
  //) => void;
  //removeEventListener: (
  //  type: string,
  //  listener: EventListenerOrEventListenerObject,
  //  options?: boolean | EventListenerOptions | undefined
  //) => void;
  //dispatchEvent: (event: Event) => boolean;
  //location: Location;
  //history: History;
  //encodeURI: (value: string) => string;
}
declare let window: Window;

export interface OppositeAudioOutputListPropType {
  oppositeName: string;
}

const OppositeAudioOutputList = (props: OppositeAudioOutputListPropType) => {
  const [neededWaiting, setNeededWaiting] = React.useState(false);
  const {
    getOppositeAudioOutputDevices,
    replaceOppositeActiveSink,
    // setSpeakerMute,
    // clearSpeakerMute,
    setOutputMute,
  } = useRemoteControl();
  // const { audioOutputBlocking } = useAppState();

  const [audioOutputDevices, setAudioOutputDevices] = useState<object[] | null>(null);
  const [oppositeAudioOutputDeviceId, setOppositeAudioOutputDeviceId] = useState('');
  const [isIpad, setIsIpad] = useState(true);
  const [isSpeakerMute, setIsSpeakerMute] = useState(false);

  useEffect(() => {
    getOppositeAudioOutputDevices(props.oppositeName).then((values: object | null) => {
      if (values) {
        // eslint-disable-next-line
        setAudioOutputDevices((values as any).devices);
        // eslint-disable-next-line
        setOppositeAudioOutputDeviceId((values as any).idSelected);
        setNeededWaiting(false);
        // eslint-disable-next-line
        setIsIpad((values as any).isIpad);
      }
    });
    // このコードだと相手の環境を拾っていない
    /*if (navigator.userAgent.includes('Macintosh') && 'ontouchend' in document) {
      setIsIpad(true);
    }*/
    // eslint-disable-next-line
  }, []);

  const _onChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    if (event) {
      replaceOppositeActiveSink(props.oppositeName, event.target.value as string);
      setOppositeAudioOutputDeviceId(event.target.value as string);
    }
  };

  const _toggleSpeakerEnabled = () => {
    console.log(!isSpeakerMute);
    setOutputMute(props.oppositeName, !isSpeakerMute);
    window.statuses[props.oppositeName] = !isSpeakerMute;
    setIsSpeakerMute(!isSpeakerMute);
  };

  // useEffect(() => {
  //   setIsSpeakerEnabled(audioOutputBlocking);
  // }, [audioOutputBlocking]);

  return (
    <div className="inputSelect" style={{ display: 'flex' }}>
      <div style={{ width: '552px' }}>
        {audioOutputDevices ? (
          audioOutputDevices.length === 0 && isIpad ? (
            <>
              <Typography variant="subtitle2">音声出力（スピーカー等）</Typography>
              <Typography>System Default Audio Output</Typography>
            </>
          ) : (
            <FormControl fullWidth>
              <Typography variant="subtitle2" gutterBottom>
                音声出力（スピーカー等）
              </Typography>
              <Select
                onChange={_onChange}
                value={oppositeAudioOutputDeviceId ?? ''}
                variant="outlined"
                disabled={neededWaiting}
              >
                {audioOutputDevices.map(device => (
                  // eslint-disable-next-line
                  <MenuItem value={(device as any).deviceId} key={(device as any).deviceId}>
                    {/*eslint-disable-next-line*/}
                    {(device as any).label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        ) : (
          <>
            <Typography variant="subtitle2">音声出力（スピーカー等）</Typography>
            <Typography>取得中...</Typography>
          </>
        )}
      </div>
      <div style={{ display: 'flex', alignItems: 'end' }}>
        {audioOutputDevices && (audioOutputDevices.length !== 0 || isIpad) && (
          <Button
            id={'opposite-speaker-enabler'}
            style={{ width: '112px', height: '41px' }}
            /*className={props.className}*/
            onClick={_toggleSpeakerEnabled}
            /*disabled={(audioOutputDevices?.length ?? 0) === 0 || props.disabled}*/
            startIcon={isSpeakerMute ? <SpeakerOffIcon /> : <SpeakerIcon />}
          >
            {isSpeakerMute ? '出力OFF' : '出力ON'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default React.memo(OppositeAudioOutputList);
