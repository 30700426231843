import React, { useEffect } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import useChatContext from '../../../../hooks/useChatContext/useChatContext';
import useStrokeState from '../../../../hooks/useStrokeState/useStrokeState';
import useRemoteControl from '../../../../hooks/useRemoteControl/useRemoteControl';
import { useAppState } from '../../../../state';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string; hide: boolean }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { disconnect } = useChatContext();
  const { supressClear } = useStrokeState();
  const { broadcastCloseAll } = useRemoteControl();
  const { isMaster, setIsEpilogue } = useAppState();

  useEffect(() => {
    const endCallButton = document.getElementById('end-call-button');
    if (endCallButton) {
      endCallButton.addEventListener('closeAll', _closeClient);
    }
    return () => {
      if (endCallButton) {
        endCallButton.removeEventListener('closeAll', _closeClient);
      }
    };
  }, []);

  const handleOnClick = async () => {
    if (isMaster) {
      await broadcastCloseAll(true);
    }

    room?.disconnect();
    disconnect();
    // 画面共有＆描画中であってもなくても描画を削除
    supressClear();
  };

  const _closeClient = () => {
    setIsEpilogue(true);

    setTimeout(() => {
      setIsEpilogue(false);
      room?.disconnect();
      disconnect();
      // 画面共有＆描画中であってもなくても描画を削除
      supressClear();
    }, 5000);
  };

  return (
    <Button
      onClick={handleOnClick}
      className={clsx(classes.button, props.className)}
      data-cy-disconnect
      style={{ display: props.hide ? 'none' : 'block' }}
      id="end-call-button"
    >
      <strong>面談終了</strong>
    </Button>
  );
}
