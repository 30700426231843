import React from 'react';

import OppositeAudioInputList from './oppositeAudioInputList/oppositeAudioInputList';
import OppositeAudioOutputList from './oppositeAudioOutputList/oppositeAudioOutputList';
import {
  DialogContent,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  Button,
  Theme,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OppositeVideoInputList from './oppositeVideoInputList/oppositeVideoInputList';
import OppositeAudioGain from './oppositeAudioGain/oppositeAudioGain';
import { AudioTrack } from 'twilio-video';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '600px',
    minHeight: '400px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
    '& .inputSelect': {
      width: 'calc(100% - 35px)',
    },
  },
  button: {
    float: 'right',
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
    },
  },
  headline: {
    marginBottom: '1.3em',
    fontSize: '1.1rem',
  },
  listSection: {
    margin: '2em 0 0.8em',
    '&:first-child': {
      margin: '1em 0 2em 0',
    },
  },
  noiseCancellationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  krispContainer: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      '&:not(:last-child)': {
        margin: '0 0.3em',
      },
    },
  },
  krispInfoText: {
    margin: '0 0 1.5em 0.5em',
  },
}));

export interface OppositeDeviceSelectionDialogPropType {
  open: boolean;
  onClose: () => void;
  oppositeName: string;
  audioTrack: AudioTrack;
  isVideoEnabled: boolean;
  isVideoSwitchedOff: boolean;
  isAudioEnabled: boolean;
}

const OppositeDeviceSelectionDialog = (props: OppositeDeviceSelectionDialogPropType) => {
  //const { isAcquiringLocalTracks } = useVideoContext();
  //const { isKrispEnabled, isKrispInstalled } = useAppState();
  //const { toggleKrisp } = useKrispToggle();
  const classes = useStyles();

  //    <Dialog open={props.open} onClose={props.onClose} classes={{ paper: classes.paper }}>
  return (
    <div
      onClick={e => {
        e.preventDefault();
        e.currentTarget.click();
      }}
    >
      <Dialog open={props.open} classes={{ paper: classes.paper }}>
        <DialogTitle>{props.oppositeName}様 音声映像調整</DialogTitle>
        <Divider />
        <DialogContent className={classes.container}>
          <div className={classes.listSection}>
            <Typography variant="h6" className={classes.headline}>
              ビデオ
            </Typography>
            <OppositeVideoInputList
              oppositeName={props.oppositeName}
              isVideoEnabled={props.isVideoEnabled}
              isVideoSwitchedOff={props.isVideoSwitchedOff}
            />
          </div>
          <Divider />
          <div className={classes.listSection}>
            <Typography variant="h6" className={classes.headline}>
              音声設定
            </Typography>
            {/*isKrispInstalled && (
            <div className={classes.noiseCancellationContainer}>
              <div className={classes.krispContainer}>
                <Typography variant="subtitle2">Noise Cancellation powered by </Typography>
                <KrispLogo />
                <Tooltip
                  title="Suppress background noise from your microphone"
                  interactive
                  leaveDelay={250}
                  leaveTouchDelay={15000}
                  enterTouchDelay={0}
                >
                  <div>
                    <InfoIconOutlined />
                  </div>
                </Tooltip>
              </div>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!isKrispEnabled}
                    checkedIcon={<SmallCheckIcon />}
                    disableRipple={true}
                    onClick={toggleKrisp}
                  />
                }
                label={isKrispEnabled ? 'Enabled' : 'Disabled'}
                style={{ marginRight: 0 }}
                disabled={isAcquiringLocalTracks}
              />
            </div>
          )}
          {isKrispInstalled && (
            <Typography variant="body1" color="textSecondary" className={classes.krispInfoText}>
              Suppress background noise from your microphone.
            </Typography>
          )*/}
            <OppositeAudioInputList
              oppositeName={props.oppositeName}
              isAudioEnabled={props.isAudioEnabled}
              className={classes.listSection}
            />
          </div>
          <div className={classes.listSection} style={{ marginTop: '20px' }}>
            <OppositeAudioGain name={props.oppositeName} audioTrack={props.audioTrack} />
          </div>
          <div className={classes.listSection}>
            <OppositeAudioOutputList oppositeName={props.oppositeName} />
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button color="primary" variant="contained" className={classes.button} onClick={props.onClose}>
            完了
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(OppositeDeviceSelectionDialog);
