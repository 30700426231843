import React, { useState, useRef, useEffect } from 'react';
import AboutDialog from '../../AboutDialog/AboutDialog';
import BackgroundIcon from '../../../icons/BackgroundIcon';
import DeviceSelectionDialog from '../../DeviceSelectionDialog/DeviceSelectionDialog';
import CheckEnvironmentDialog from '../../CheckEnvironmentDialog/CheckEnvironmentDialog';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import InfoIconOutlined from '../../../icons/InfoIconOutlined';
import MoreIcon from '@material-ui/icons/MoreVert';
//import StartRecordingIcon from '../../../icons/StartRecordingIcon';
//import StopRecordingIcon from '../../../icons/StopRecordingIcon';
import SettingsIcon from '../../../icons/SettingsIcon';
import { Button, styled, Theme, useMediaQuery, Menu as MenuContainer, MenuItem, Typography } from '@material-ui/core';
import { isSupported } from '@twilio/video-processors';

import { useAppState } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
//import useIsRecording from '../../../hooks/useIsRecording/useIsRecording';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import FlipCameraIcon from '../../../icons/FlipCameraIcon';
import useFlipCameraToggle from '../../../hooks/useFlipCameraToggle/useFlipCameraToggle';
import useDevices from '../../../hooks/useDevices/useDevices';
import useRemoteControl from '../../../hooks/useRemoteControl/useRemoteControl';
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import { LocalVideoTrack } from 'twilio-video';
import { SELECTED_VIDEO_INPUT_KEY, DEFAULT_VIDEO_CONSTRAINTS } from '../../../constants';
//import Gainer from './gainer';

export const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '1.5em',
  marginRight: '0.3em',
});

export default function Menu(props: { buttonClassName?: string }) {
  const anchorRef = useRef<HTMLButtonElement>(null);

  // eslint-disable-next-line
  const { videoInputDevices } = useDevices();
  // eslint-disable-next-line
  const { replyOppositeVideoInputDevices } = useRemoteControl();
  const { setIsChatWindowOpen } = useChatContext();
  // eslint-disable-next-line
  const { room, setIsBackgroundSelectionOpen } = useVideoContext();
  const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();
  //const { isFetching, updateRecordingRules, roomType } = useAppState();
  const { isPatient } = useAppState();
  //const isRecording = useIsRecording();
  const { localTracks } = useVideoContext();

  const [aboutOpen, setAboutOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [checkEnvironmentOpen, setCheckEnvironmentOpen] = useState(false);
  //const [cachedVideoInputDevices, setCachedVideoInputDevices, getCachedVideoInputDevices] = useStateEx<object[]>([]);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
  );
  const [isStealth, setIsStealth] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const localVideoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack | undefined;
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  // eslint-disable-next-line
  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;

  /*useEffect(() => {
    setCachedVideoInputDevices(videoInputDevices);
  }, [videoInputDevices]);*/

  const _setSettingsOpen = () => {
    setIsStealth(true);
    setSettingsOpen(true);
  };
  const _setSettingsClose = () => {
    setSettingsOpen(false);
    setIsStealth(false);
  };

  // eslint-disable-next-line
  function replaceTrack(value: string) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    //e.preventDefault();
    const newDeviceId = value;
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    //setNeededWaiting(true);
    setTimeout(() => {
      localVideoTrack
        ?.restart({
          ...(DEFAULT_VIDEO_CONSTRAINTS as object),
          deviceId: { exact: newDeviceId },
        })
        .then(() => {
          //setNeededWaiting(false);
        })
        .catch(e => {
          window.alert(e.toString());
        });
    }, 0);
  }

  useEffect(() => {
    window.addEventListener('openOppositeDeviceSelectionDialog', _setSettingsOpen);
    window.addEventListener('closeOppositeDeviceSelectionDialog', _setSettingsClose);
    //menuConnected.addEventListener('getOppositeVideoInputDevices', _getOppositeVideoInputDevices);
    //menuConnected.addEventListener('replaceOppositeVideoTrack', _replaceOppositeVideoTrack);
    return () => {
      window.removeEventListener('openOppositeDeviceSelectionDialog', _setSettingsOpen);
      window.removeEventListener('closeOppositeDeviceSelectionDialog', _setSettingsClose);
      //menuConnected.removeEventListener('getOppositeVideoInputDevices', _getOppositeVideoInputDevices);
      //menuConnected.removeEventListener('replaceOppositeVideoTrack', _replaceOppositeVideoTrack);
    };
  }, []);

  return isPatient ? (
    <div id="menu-connected">
      {/*<Gainer />*/}
      <DeviceSelectionDialog
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
          setMenuOpen(false);
        }}
        isStealth={true}
      />
    </div>
  ) : (
    <div id="menu-connected">
      <Button
        onClick={() => setMenuOpen(isOpen => !isOpen)}
        ref={anchorRef}
        className={props.buttonClassName}
        data-cy-more-button
      >
        {isMobile ? (
          <MoreIcon />
        ) : (
          <>
            設定
            <ExpandMoreIcon />
          </>
        )}
      </Button>
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen(isOpen => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: isMobile ? -55 : 'bottom',
          horizontal: 'center',
        }}
      >
        {/* {roomType !== 'peer-to-peer' && roomType !== 'go' && (
          <MenuItem
            disabled={isFetching}
            onClick={() => {
              setMenuOpen(false);
              if (isRecording) {
                updateRecordingRules(room!.sid, [{ type: 'exclude', all: true }]);
              } else {
                updateRecordingRules(room!.sid, [{ type: 'include', all: true }]);
              }
            }}
            data-cy-recording-button
          >
            <IconContainer>{isRecording ? <StopRecordingIcon /> : <StartRecordingIcon />}</IconContainer>
            <Typography variant="body1">{isRecording ? 'Stop' : 'Start'} Recording</Typography>
          </MenuItem>
        )} */}
        {flipCameraSupported && (
          <MenuItem disabled={flipCameraDisabled} onClick={toggleFacingMode}>
            <IconContainer>
              <FlipCameraIcon />
            </IconContainer>
            <Typography variant="body1">カメラ切り替え</Typography>
          </MenuItem>
        )}

        <MenuItem onClick={() => setSettingsOpen(true)}>
          <IconContainer>
            <SettingsIcon />
          </IconContainer>
          <Typography variant="body1">カメラ・音声設定</Typography>
        </MenuItem>

        {isSupported && (
          <MenuItem
            onClick={() => {
              setIsBackgroundSelectionOpen(true);
              setIsChatWindowOpen(false);
              setMenuOpen(false);
            }}
          >
            <IconContainer>
              <BackgroundIcon />
            </IconContainer>
            <Typography variant="body1">背景設定</Typography>
          </MenuItem>
        )}

        {/* TODO: check */}

        {/* <MenuItem onClick={() => setAboutOpen(true)}>
          <IconContainer>
            <InfoIconOutlined />
          </IconContainer>
          <Typography variant="body1">About</Typography>
        </MenuItem> */}
      </MenuContainer>
      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      />
      <DeviceSelectionDialog
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
          setMenuOpen(false);
        }}
        isStealth={isStealth}
      />
      <CheckEnvironmentDialog
        open={checkEnvironmentOpen}
        onClose={() => {
          setCheckEnvironmentOpen(false);
          setMenuOpen(false);
        }}
      />
      {/*<Gainer />*/}
    </div>
  );
}
