import NativeApiManager from './nativeApiManager';

export type SetMuteRequestData = {
  isMute: string;
};

export type SetMuteResponseData = {
  volume: '0' | '1' | '-1';
};

type Provider<T> = T extends { provider: infer P } ? P : never;

export class NativeApi {
  constructor(public nativeApiManager: NativeApiManager) {}

  init() {
    this.nativeApiManager.init();
  }

  setMute(request: SetMuteRequestData) {
    this.nativeApiManager.postSync<SetMuteResponseData>('setMute', request);
  }
}
