import React, { useEffect } from 'react';
import ParticipantInfo from '../ParticipantInfo/ParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import { Participant as IParticipant, LocalAudioTrack } from 'twilio-video';
import {
  PARTICIPANT_LIST_ALLOWED_TRACKS,
  PARTICIPANT_LIST_PRIORITIZED_TRACK,
  PARTICIPANT_MAX_TRACKS,
} from '../../constants';
import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

interface ParticipantProps {
  participant: IParticipant | null;
  videoOnly?: boolean;
  enableScreenShare?: boolean;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
}

export default function Participant({
  participant,
  videoOnly,
  enableScreenShare,
  onClick,
  isSelected,
  isLocalParticipant,
  hideParticipant,
}: ParticipantProps) {
  const { whoIs, isMaster, isStandBy } = useAppState();
  const { localTracks } = useVideoContext();
  const audioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;

  useEffect(() => {
    if (!isMaster && participant && participant.identity === whoIs) {
      audioTrack.disable();
    }
    //const handle_ = setInterval(_timerProc, 3000);
    //setHandle(handle_);
    //window.addEventListener('changeSpeakerMute', _changeSpeakerMute);
    return () => {
      //window.removeEventListener('changeSpeakerMute', _changeSpeakerMute);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isStandBy === false) {
      audioTrack.enable();
    }
    // eslint-disable-next-line
  }, [isStandBy]);

  return participant ? (
    <ParticipantInfo
      participant={participant}
      onClick={onClick}
      isSelected={isSelected}
      isLocalParticipant={isLocalParticipant}
      hideParticipant={hideParticipant}
    >
      <ParticipantTracks
        participant={participant}
        videoOnly={videoOnly}
        enableScreenShare={enableScreenShare}
        isLocalParticipant={isLocalParticipant}
        allowedTracks={PARTICIPANT_LIST_ALLOWED_TRACKS}
        prioritizedTrack={PARTICIPANT_LIST_PRIORITIZED_TRACK}
        maxTracks={PARTICIPANT_MAX_TRACKS}
      />
    </ParticipantInfo>
  ) : (
    <></>
  );
}
